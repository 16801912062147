<template>
    <div class="container-fluid m-0 p-o mt-3">

        <div class="row">

            <div class="col-3 align-items-start">

                <AdminMenu activeMenuItem="trainingmethodtester" />

            </div>

            <div class="col-9">
                <div class="container mt-3">

                    <!-- Training Method Recommendation Input -->
                    <div class="row">
                        <div class="col">
                            <div class="card border-primary mb-3">
                                <div class="card-header"><h1>Athlete Input</h1></div>
                                <div class="card-body">

                                    <div class="container my-3">

                                        <div class="row my-3">
                                            <div class="col-4">
                                                <h4 class="text-start">Fitness Goal</h4>
                                            </div>
                                            <div class="col">
                                                <select class="form-select" id="selectFitnessGoal" v-model="trainingMethodRecommendationInputDto.fitnessGoal" @change="getRecommendations">
                                                    <option v-for="fitnessGoal in fitnessGoals" :value="fitnessGoal.id" :key="fitnessGoal.id">
                                                        {{ fitnessGoal.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row my-3">
                                            <div class="col-4">
                                                <h4 class="text-start">Blood Pressure</h4>
                                            </div>
                                            <div class="col">
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col">
                                                            <input type="text" class="form-control" style="max-width: 10rem;" id="inputBloodPressureSystolic" placeholder="Systolic" v-model="trainingMethodRecommendationInputDto.bloodPressureSystolic" @change="getRecommendations" />
                                                        </div>
                                                        <div class="col">
                                                            <h4>/</h4>
                                                        </div>
                                                        <div class="col">
                                                            <input type="text" class="form-control" style="max-width: 10rem;" id="inputBloodPressureDiastolic" placeholder="Diastolic" v-model="trainingMethodRecommendationInputDto.bloodPressureDiastolic" @change="getRecommendations" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row my-3">
                                            <div class="col-4">
                                                <h4 class="text-start">Resting Heart Rate</h4>
                                            </div>
                                            <div class="col">
                                                <input type="text" class="form-control" id="inputRestingHeartRate" placeholder="Resting Heart Rate" v-model="trainingMethodRecommendationInputDto.restingHeartRate" @change="getRecommendations" />
                                            </div>
                                        </div>

                                        <div class="row my-3">
                                            <div class="col-4">
                                                <h4 class="text-start">Stress Score</h4>
                                            </div>
                                            <div class="col">
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col">
                                                            <input type="range" class="form-range" min="1" max="10" step="1" id="stressScoreInput" v-model="trainingMethodRecommendationInputDto.stressScore" @change="getRecommendations" />
                                                        </div>
                                                        <div class="col-2">
                                                            <small class="text-muted">{{ trainingMethodRecommendationInputDto.stressScore }}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row my-3">
                                            <div class="col-4">
                                                <h4 class="text-start">Training Age</h4>
                                            </div>
                                            <div class="col">
                                                <select class="form-select" id="selectTrainingAge" v-model="trainingMethodRecommendationInputDto.trainingAge" @change="getRecommendations">
                                                    <option v-for="trainingAge in trainingAges" :value="trainingAge.id" :key="trainingAge.id">
                                                        {{ trainingAge.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row my-3">
                                            <div class="col-4">
                                                <h4 class="text-start">Workouts per Week</h4>
                                            </div>
                                            <div class="col">
                                                <select class="form-select" id="selectWorkoutsPerWeek" v-model="trainingMethodRecommendationInputDto.workoutsPerWeek" @change="getRecommendations">
                                                    <option v-for="workoutPerWeek in workoutsPerWeek" :value="workoutPerWeek.id" :key="workoutPerWeek.id">
                                                        {{ workoutPerWeek.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row my-3">
                                            <div class="col-4">
                                                <h4 class="text-start">Workout Session Time</h4>
                                            </div>
                                            <div class="col">
                                                <select class="form-select" id="selectWorkoutSessionTime" v-model="trainingMethodRecommendationInputDto.workoutSessionTimeInMinutes" @change="getRecommendations">
                                                    <option v-for="workoutSessionTime in workoutSessionTimes" :value="workoutSessionTime.id" :key="workoutSessionTime.id">
                                                        {{ workoutSessionTime.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row my-3">
                                            <div class="col-4">
                                                <h4 class="text-start">Workout Structure</h4>
                                            </div>
                                            <div class="col">
                                                <select class="form-select" id="selectWorkoutStructure" v-model="trainingMethodRecommendationInputDto.workoutStructure" @change="getRecommendations">
                                                    <option v-for="workoutStructure in workoutStructures" :value="workoutStructure.id" :key="workoutStructure.id">
                                                        {{ workoutStructure.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                    </div> <!-- End: Container -->

                                </div> <!-- End: Card Body -->
                            </div>
                        </div>

                        <div class="col-4">
                            <div class="row">
                                <div class="col">
                                    <div class="card border-primary mb-3">
                                        <div class="card-header"><h1>Info</h1></div>
                                        <div class="card-body">

                                            <div class="container">
                                                <div class="row">
                                                    <div class="col text-start">
                                                        <h4>Possible Matches</h4>
                                                    </div>
                                                    <div class="col-2">
                                                        <h6>{{ possiblerecommendationcount }}</h6>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col text-start">
                                                        <h4>Complete Matches</h4>
                                                    </div>
                                                    <div class="col-2">
                                                        <h6>{{ completematchcount }}</h6>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col text-start">
                                                        <h4>Partial Matches (4)</h4>
                                                    </div>
                                                    <div class="col-2">
                                                        <h6>{{ partialmatchfourcount }}</h6>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col text-start">
                                                        <h4>Partial Matches (3)</h4>
                                                    </div>
                                                    <div class="col-2">
                                                        <h6>{{ partialmatchthreecount }}</h6>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="col">
                                    <div class="card border-primary mb-3">
                                        <div class="card-header"><h1>Preset Inputs</h1></div>
                                        <div class="card-body">

                                            <div class="container">
                                                <div class="row">
                                                    <h4>Select Athlete</h4>
                                                </div>

                                                <div class="row">
                                                    <select class="form-select" id="dropdownSelectClient" v-model="selectedClientId" @change="selectedClientChanged">
                                                        <option v-for="client in clients" :value="client.id" :key="client.id">
                                                            {{ client.description }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Training Method Recommendation Output -->
                    <div class="row">
                        <div class="col">
                            <div class="card border-primary mb-3">
                                <div class="card-header"><h1>Training Method Recommendation Results</h1></div>
                                <div class="card-body">
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="text-start">Training Method</th>
                                                <th scope="col">Goal</th>
                                                <th scope="col">Systolic</th>
                                                <th scope="col">Diastolic</th>
                                                <th scope="col">Heart Rate</th>
                                                <th scope="col">Stress</th>
                                                <th scope="col">Training</th>
                                                <th scope="col">Workout #</th>
                                                <th scope="col">Workout Time</th>
                                                <th scope="col">Workout Struct</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr v-for="recommendation in recommendations" :key="recommendation.id">
                                                <th scope="row" class="text-start">
                                                    {{ recommendation.trainingMethodName }}
                                                </th>
                                                <td>
                                                    <RecommendationInputStatus :recommendationWeight=recommendation.fitnessGoalRecommendationWeight />
                                                </td>
                                                <td>
                                                    <RecommendationInputStatus :recommendationWeight=recommendation.bloodPressureSystolicRecommendationWeight />
                                                </td>
                                                <td>
                                                    <RecommendationInputStatus :recommendationWeight=recommendation.bloodPressureDiastolicRecommendationWeight />
                                                </td>
                                                <td>
                                                    <RecommendationInputStatus :recommendationWeight=recommendation.restingHeartRateRecommendationWeight />
                                                </td>
                                                <td>
                                                    <RecommendationInputStatus :recommendationWeight=recommendation.stressScoreRecommendationWeight />
                                                </td>
                                                <td>
                                                    <RecommendationInputStatus :recommendationWeight=recommendation.trainingAgeRecommendationWeight />
                                                </td>
                                                <td>
                                                    <RecommendationInputStatus :recommendationWeight=recommendation.workoutsPerWeekRecommendationWeight />
                                                </td>
                                                <td>
                                                    <RecommendationInputStatus :recommendationWeight=recommendation.workoutSessionTimeInMinuesRecommendationWeight />
                                                </td>
                                                <td>
                                                    <RecommendationInputStatus :recommendationWeight=recommendation.workoutStructureRecommendationWeight />
                                                </td>
                                                <td>
                                                    <router-link class="nav-link" :to="`/training-method/${recommendation.trainingMethodId}/edit`">
                                                        <img src="images/icon-edit-small.png" />
                                                    </router-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <img src="../../assets/loading-data.gif" style="position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 999;" v-show="requestingData" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</template>

<style scoped src="../../../public/bootstrap-sketchy.css">
</style>

<script>
    import axios from 'axios';
    import AdminMenu from '../../components/admin-menu.vue'
    import RecommendationInputStatus from '../../components/recommendation-input-status.vue';
    import { defineComponent } from "vue";

    export default defineComponent({
        components: {
            AdminMenu,
            RecommendationInputStatus
        },

        data() {
            return {
                fitnessGoals: [
                    { id: null, name: '-' },
                    { id: 1, name: 'Maximal Strength / Power' },
                    { id: 2, name: 'Hypertrophy' },
                    { id: 3, name: 'Strength Endurance (Fat Loss)' },
                ],
                trainingAges: [
                    { id: null, name: '-' },
                    { id: 1, name: 'Beginner' },
                    { id: 2, name: 'Novice' },
                    { id: 3, name: 'Intermediate' },
                    { id: 4, name: 'Advanced' },
                    { id: 5, name: 'Elite' }
                ],
                workoutsPerWeek: [
                    { id: 1, name: '1' },
                    { id: 2, name: '2' },
                    { id: 3, name: '3' },
                    { id: 4, name: '4' },
                    { id: 5, name: '5' },
                    { id: 6, name: '6' },
                    { id: 7, name: '7' }
                ],
                workoutSessionTimes: [
                    { id: 30, name: '30 minutes' },
                    { id: 45, name: '45 minutes' },
                    { id: 60, name: '60 minutes' },
                    { id: 90, name: '90 minutes' },
                    { id: 120, name: '120 minutes' }
                ],
                workoutStructures: [
                    { id: 1, name: 'Upper / Lower' },
                    { id: 2, name: 'Body Part Split' },
                    { id: 3, name: 'Full Body' }
                ],
                requestingData: false,
                hasRecommendations: false,
                selectedClientId: -1,
                clients: null,
                trainingMethodRecommendationInputDto: {
                    bloodPressureSystolic: null,
                    bloodPressureDiastolic: null,
                    fitnessGoal: null,
                    restingHeartRate: null,
                    stressScore: 5,
                    trainingAge: null,
                    workoutsPerWeek: null,
                    workoutSessionTimeInMinutes: null,
                    workoutStructure: null
                },
                recommendations: [],
                debugData: {
                    isDebugOn: false,
                    message: ''
                }
            }
        },

        computed: {
            possiblerecommendationcount() {
                return this.recommendations.length ?? 0;
            },
            completematchcount() {
                return this.recommendations.filter(obj => { return obj.totalFiveStarRecommendations == obj.totalRecommendationInputs }).length ?? 0;
            },
            partialmatchfourcount() {
                return this.recommendations.filter(obj => { return obj.totalRecommendationWeighted >= 4 }).length ?? 0;
            },
            partialmatchthreecount() {
                return this.recommendations.filter(obj => { return obj.totalRecommendationWeighted >= 3 }).length ?? 0;
            }
        },
        async mounted() {
            //const response = await axios.post('https://cerebrocoachwebapi.azurewebsites.net/constants/getsampleclientdatarecommendationinput');
            const response = await axios.get(`${process.env.VUE_APP_API}/constants/getsampleclientdatarecommendationinput`);
            this.clients = response.data;
            this.debugData.message = 'created';
        },
        methods: {
            async getRecommendations() {
                // documentation: https://axios-http.com/docs/post_example
                this.requestingData = true;
                var response = null;
                try {
                    response = await axios.post(`${process.env.VUE_APP_API}/recommendation/getrecommendations`, this.trainingMethodRecommendationInputDto);
                    //response = await axios.post('https://cerebrocoachwebapi.azurewebsites.net/recommendation/getrecommendations', this.trainingMethodRecommendationInputDto);
                }
                catch (err) {
                    this.debugData.message = err.message;
                }
                this.debugData.message = response.data;
                this.recommendations = response.data;
                this.requestingData = false;
                this.hasRecommendations = this.recommendations != null && this.recommendations.length > 0;
            },
            async selectedClientChanged() {
                var selectedClient = this.clients.find(x => { return x.id == this.selectedClientId });

                this.trainingMethodRecommendationInputDto.bloodPressureDiastolic = selectedClient.trainingMethodRecommendationInput.bloodPressureDiastolic;
                this.trainingMethodRecommendationInputDto.bloodPressureSystolic = selectedClient.trainingMethodRecommendationInput.bloodPressureSystolic;
                this.trainingMethodRecommendationInputDto.fitnessGoal = selectedClient.trainingMethodRecommendationInput.fitnessGoal;
                this.trainingMethodRecommendationInputDto.restingHeartRate = selectedClient.trainingMethodRecommendationInput.restingHeartRate;
                this.trainingMethodRecommendationInputDto.stressScore = selectedClient.trainingMethodRecommendationInput.stressScore;
                this.trainingMethodRecommendationInputDto.trainingAge = selectedClient.trainingMethodRecommendationInput.trainingAge;
                this.trainingMethodRecommendationInputDto.workoutSessionTimeInMinutes = selectedClient.trainingMethodRecommendationInput.workoutSessionTimeInMinutes;
                this.trainingMethodRecommendationInputDto.workoutsPerWeek = selectedClient.trainingMethodRecommendationInput.workoutsPerWeek;
                this.trainingMethodRecommendationInputDto.workoutStructure = selectedClient.trainingMethodRecommendationInput.workoutStructure;

                await this.getRecommendations();
            }
        }
    });
</script>
