<template>
    <table class="table table-hover">        
        <tbody>
            <tr>
                <th>Recorded Time (UTC)</th>
                <th>{{ $route.params.observation }}</th>
            </tr>
            <template v-for="observation in observations" :key="observation.id">
                <tr>
                    <td class="text-start">
                        {{ observation.recordedTimeUtc }}
                    </td>
                    <td class="text-start">
                        {{ observation.dataValue }}
                    </td>
                </tr>
            </template>
        </tbody>
    </table>
</template>

<script>
    import axios from 'axios';
    import { defineComponent } from "vue";
    import { useHead } from '@vueuse/head';

    export default defineComponent({
        data() {
            return {
                observations: null
            }
        },
        async created() {
            const response = await axios.post(`${process.env.VUE_APP_API}/athlete/getathleteobservationchartdata`, 'athleteId=' + this.$route.params.athleteId + '&observation=' + this.$route.params.observation);
            this.observations = response.data;
            useHead({
                title: `Cerebro: Athlete Observations ${this.$route.params.athleteId}`
            });
        },
        methods: {
            flagChange() {
                this.isDataChanged = true;
            }
        }
    });
</script>
