import { createApp } from 'vue';
import { createHead } from '@vueuse/head';

import App from './App.vue';
import router from './router';

export const API = process.env.VUE_APP_API;

const app = createApp(App);
const head = createHead();

app
    .use(head)
    .use(router)
    .mount('#app');
