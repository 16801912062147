<template>
    <div class="container mt-3">
        <div class="card border-primary">
            <div class="card-header">
                <div class="container">
                    <div class="row">
                        <div class="col align-middle">
                            <h1>{{ trainingMethod.name || " " }}</h1>
                        </div>
                        <div class="col-2 align-middle">
                            <div class="card-body">
                                <button type="submit" class="btn" :class=" {'btn-primary': !isDataChanged, 'btn-danger': isDataChanged}" @click="saveTrainingMethod">Save</button>
                            </div>
                        </div>
                    </div>

                    <div class="card-body text-start">
                        <template v-for="item in trainingMethod.trainingMethodRecommendationCriterionInstances" :key="item.trainingMethodRecommendationCriterionId">
                            <div class="card border-primary m-3 float-sm-start" style="width: 18rem; height: 12rem;">
                                <div class="card-header w-100">
                                    <div class="container">
                                        <div class="row align-middle">
                                            <div class="col pt-2">
                                                {{ item.recommendationCriterionName }}
                                            </div>
                                        </div>
                                        <div class="row align-middle">
                                            <div class="col pt-2">
                                                {{ item.recommendationCriterionRange }}
                                            </div>
                                            <div class="col-4 align-middle">
                                                <button type="button" class="btn" :class="{ 'btn-success': item.isEnabled, 'btn-warning': item.isDisabled, 'btn-secondary': item.isUndefined }" style="width: 5rem;" @click="changeCriterionStatus(item.trainingMethodRecommendationCriterionId)">
                                                    <template v-if="item.isEnabled">
                                                        Enabled
                                                    </template>
                                                    <template v-else-if="item.isDisabled">
                                                        Disabled
                                                    </template>
                                                    <template v-else-if="item.isUndefined">
                                                        N/A
                                                    </template>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="item.isEnabled" class="card-body h-100 d-flex align-items-center">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-10">
                                                <input type="range" class="form-range" min="1" max="5" step="1" id="itemWeightRange" v-model="item.weight" @change="flagChange">
                                            </div>
                                            <div class="col">
                                                <h3>{{ item.weight }}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="item.isUndefined" class="card-body h-100 d-flex align-items-center">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col text-muted">
                                                This is not relevant for this element
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="item.isDisabled" class="card-body h-100 d-flex align-items-center alert alert-dismissible alert-danger">
                                    Contraindicated
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <table class="table table-hover">
                <thead>
                    <tr>
                        <th scope="col" class="text-start">Template Name</th>
                        <th scope="col">Workouts per Week</th>
                        <th scope="col">Session Time (In Minutes)</th>
                        <th scope="col">Workout Structure</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="trainingMethodTemplate in trainingMethod.trainingMethodTemplates" :key="trainingMethodTemplate.trainingMethodTemplateId">
                        <th scope="row" class="text-start">
                            <input type="text" class="form-control" style="max-width: 10rem;" id="inputTrainingTemplateDescription" placeholder="Template Description" v-model="trainingMethodTemplate.description" @change="flagChange" />
                        </th>
                        <td>
                            <select class="form-select" id="selectWorkoutsPerWeek" v-model="trainingMethodTemplate.workoutsPerWeek" @change="flagChange">
                                <option v-for="workoutPerWeek in workoutsPerWeek" :value="workoutPerWeek.id" :key="workoutPerWeek.id">
                                    {{ workoutPerWeek.name }}
                                </option>
                            </select>
                        </td>
                        <td>
                            <select class="form-select" id="selectWorkoutSessionTime" v-model="trainingMethodTemplate.sessionTimeInMinutes" @change="flagChange">
                                <option v-for="workoutSessionTime in workoutSessionTimes" :value="workoutSessionTime.id" :key="workoutSessionTime.id">
                                    {{ workoutSessionTime.name }}
                                </option>
                            </select>
                        </td>
                        <td>
                            <select class="form-select" id="selectWorkoutStructure" v-model="trainingMethodTemplate.workoutStructure" @change="flagChange">
                                <option v-for="workoutStructure in workoutStructures" :value="workoutStructure.id" :key="workoutStructure.id">
                                    {{ workoutStructure.name }}
                                </option>
                            </select>
                        </td>
                        <td>
                            <input type="image" src="../../images/delete-icon-small.png" @click="deleteTemplate(trainingMethodTemplate.trainingMethodTemplateId)" />
                        </td>
                    </tr>

                    <tr>
                        <td colspan="5">
                            <button type="submit" class="btn btn-primary" @click="addTemplate">Add Template</button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="card-header">
                <div class="container">
                    <div class="row">
                        <div class="col align-items-center mt-3">
                            <h1>{{ trainingMethod.name || " " }}</h1>
                        </div>
                        <div class="col-2">
                            <div class="card-body">
                                <button type="submit" class="btn" :class="{'btn-primary': !isDataChanged, 'btn-danger': isDataChanged}" @click="saveTrainingMethod">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>

<script>
    import axios from 'axios';
    import { defineComponent } from "vue";
    import { useHead } from '@vueuse/head';

    export default defineComponent({
        data() {
            return {
                isDataChanged: false,
                trainingMethod: null,
                debugData: {
                    isDebugOn: true,
                    message: ''
                },
                workoutsPerWeek: [
                    { id: 1, name: '1' },
                    { id: 2, name: '2' },
                    { id: 3, name: '3' },
                    { id: 4, name: '4' },
                    { id: 5, name: '5' },
                    { id: 6, name: '6' },
                    { id: 7, name: '7' }
                ],
                workoutSessionTimes: [
                    { id: 30, name: '30 minutes' },
                    { id: 45, name: '45 minutes' },
                    { id: 60, name: '60 minutes' },
                    { id: 90, name: '90 minutes' },
                    { id: 120, name: '120 minutes' }
                ],
                workoutStructures: [
                    { id: 1, name: 'Upper / Lower' },
                    { id: 2, name: 'Body Part Split' },
                    { id: 3, name: 'Full Body' }
                ]
            }
        },
        async created() {
            const response = await axios.post(`${process.env.VUE_APP_API}/trainingMethod/gettrainingmethod`, 'trainingMethodId=' + this.$route.params.trainingMethodId);
            this.trainingMethod = response.data;
            for (const element of this.trainingMethod.trainingMethodRecommendationCriterionInstances) {
                element.isEnabled = element.weight > 0;
                element.isDisabled = element.weight < 0;
                element.isUndefined = element.weight == null;
            }
            this.debugData.message = response.data;
            useHead({
                title: `Cerebro: Training Method ${this.$route.params.trainingMethodId} Edit`
            });
        },
        methods: {
            addTemplate() {
                var blankTrainingMethodTemplate = { trainingMethodId: this.trainingMethod.trainingMethodId, trainingMethodName: this.trainingMethod.name, trainingMethodTemplateId: -1, description: null, workoutsPerWeek: null, workoutSessionTimes: null, workoutStructure: null };
                this.trainingMethod.trainingMethodTemplates.push(blankTrainingMethodTemplate);
                this.flagChange();
            },
            deleteTemplate(templateId) {
                var template = this.trainingMethod.trainingMethodTemplates.find(x => x.trainingMethodTemplateId == templateId);
                var index = this.trainingMethod.trainingMethodTemplates.indexOf(template);
                if (index !== -1) { this.trainingMethod.trainingMethodTemplates.splice(index, 1); }
                this.flagChange();
            },
            criterionButtonState() {
                return {
                    'btn': true,
                    'btn-success': true
                }
            },
            changeCriterionStatus(trainingMethodRecommendationCriterionId) {
                var criterion = this.trainingMethod.trainingMethodRecommendationCriterionInstances.find(x => { return x.trainingMethodRecommendationCriterionId == trainingMethodRecommendationCriterionId });
                if (criterion.isEnabled) {
                    criterion.isEnabled = false;
                    criterion.isDisabled = true;
                    criterion.weight = -100;
                } else if (criterion.isDisabled) {
                    criterion.isDisabled = false;
                    criterion.isUndefined = true;
                    criterion.weight = null;
                } else {
                    criterion.isUndefined = false;
                    criterion.isEnabled = true;
                    criterion.weight = 5;
                }

                this.flagChange();
            },
            flagChange() {
                this.isDataChanged = true;
            },
            async saveTrainingMethod() {
                // documentation: https://axios-http.com/docs/post_example
                await axios.post(`${process.env.VUE_APP_API}/trainingmethod/addupdatetrainingmethod`, this.trainingMethod);

                this.isDataChanged = false;
            }
        }
    });
</script>
