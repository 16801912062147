<template>
    <section class="athletes">
        <div class="athletes-header">
            <PinnedButtonGroup v-if="slidePanelsClosed" rightValue="0px">
                <PinnedButton @click="toggleMenu('tips')">
                    <span class="material-symbols-outlined">tips_and_updates</span>
                </PinnedButton>
            </PinnedButtonGroup>

            <CerebroSearch search-type="athlete" show-filters="true" @searchCriteriaUpdated="debounceSearchAthletes" @searchSubmitted="handleAthleteSearchSubmit" />

            <div v-if="!isSidebarPinned" class="col-4">
                <transition name="slide-left">
                    <SlidePanel v-if="showTips" type="tips" :isRecommendationsOption="false" :isNotesOption="false" @toggleMenu="toggleMenu($event)">
                        <div class="card border-primary mb-3">
                            <div class="card-header">
                                <h2>
                                    Quick Tips
                                    <span class="material-symbols-outlined btn btn-outline-primary btn-icon" @click="pinSidebar('tips')">lock_open</span>
                                </h2>
                            </div>
                            <QuickTipsAthletesView />
                        </div>
                    </SlidePanel>
                </transition>
            </div>
        </div>
        <div class="athletes-results" :class="isSidebarPinned && 'athletes-results--sidebar-fixed'">

            <transition name="fade">
                <div v-if="isSearching" class="overlay">
                    <img src="../assets/pre-loader-search.gif" />
                </div>
            </transition>

            <div class="athletes-results__content">

                <ul class="athletes-results__list">
                    <li v-for="athlete in athletes" class="athletes-results__item" :key="athlete.athleteId">
                        <AthleteSearchCard :athlete="athlete" />
                    </li>
                </ul>

            </div>

            <aside v-if="isSidebarPinned" class="athletes-results__sidebar">
                <div class="card border-primary">
                    <div class="card-header">
                        <h2>
                            Quick Tips
                            <span class="material-symbols-outlined btn btn-outline-primary btn-icon" @click="unpinSidebar('tips')">lock_open</span>
                        </h2>
                    </div>
                    <QuickTipsAthletesView />
                </div>
            </aside>

        </div>
    </section>
</template>

<style>
    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: 0.3;
        z-index: 99;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: all 0.4s ease-in-out;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .slide-left-enter-active,
    .slide-left-leave-active {
        transition: all 0.4s ease-in-out;
    }

    .slide-left-enter-from,
    .slide-left-leave-to {
        transform: translateX(500px);
    }

    .athletes {
        padding: 0 10px;
    }

    .athletes-results {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        grid-template-areas:
            "content";
    }

    .athletes-results--sidebar-fixed {
        grid-template-areas:
            "content"
            "sidebar";
    }

    .athletes-results__content {
        grid-area: content;
    }

    .athletes-results__list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .athletes-results__sidebar {
        position: relative;
        grid-area: sidebar;
    }

    @media screen and (min-width: 576px) {
        .athletes-results__list {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media screen and (min-width: 768px) {
        .athletes-results {
            grid-template-areas:
                "content";
        }

        .athletes-results--sidebar-fixed {
            grid-template-columns: repeat(5, 1fr);
            grid-template-areas:
                "content content content sidebar sidebar";
        }

        .athletes-results__list {
            grid-template-columns: repeat(3, 1fr);
        }

        .athletes-results--sidebar-fixed .athletes-results__list {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media screen and (min-width: 992px) {
        .athletes-results__list {
            grid-template-columns: repeat(4, 1fr);
        }

        .athletes-results--sidebar-fixed {
            grid-template-columns: repeat(4, 1fr);
            grid-template-areas:
                "content content content sidebar";
        }

        .athletes-results--sidebar-fixed .athletes-results__list {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media screen and (min-width: 1200px) {
        .athletes-results__list {
            grid-template-columns: repeat(5, 1fr);
        }

        .athletes-results--sidebar-fixed .athletes-results__list {
            grid-template-columns: repeat(4, 1fr);
        }
    }
</style>

<script>
    import axios from 'axios';
    import { defineComponent } from "vue";
    import AthleteSearchCard from '@/components/athlete-search-card.vue';
    import PinnedButtonGroup from '@/components/PinnedButtonGroup.vue';
    import PinnedButton from '@/components/PinnedButtons.vue';
    import SlidePanel from '@/components/SlidePanel.vue';
    import QuickTipsAthletesView from '@/components/quick-tips/quick-tips-athletesView.vue'
    import CerebroSearch from '@/components/cerebro-search.vue';

    export default defineComponent({
        components: {
            AthleteSearchCard,
            PinnedButton,
            PinnedButtonGroup,
            SlidePanel,
            QuickTipsAthletesView,
            CerebroSearch,
        },

        data() {
            return {
                isDataChanged: false,
                isSearching: true,

                sidebarPinned: false,
                showTips: false,
                isTipsPinned: false,

                athletes: [],
                searchCriteria: '',
                debounce: null
            }
        },

        async created() {
            this.isSearching = true;
            //console.log(process.env.VUE_APP_API);
            this.searchAthletes();
            this.isSearching = false;
        },

        computed: {
            slidePanelsClosed() {
                return !this.showTips;
            },

            isSidebarPinned() {
                return this.isTipsPinned;
            }
        },

        methods: {
            debounceSearchAthletes(updatedSearchCriteria) {
                clearTimeout(this.debounce);

                if (updatedSearchCriteria !== this.searchCriteria) {
                    this.searchCriteria = updatedSearchCriteria;
                }

                this.debounce = setTimeout(() => {
                    this.searchAthletes();
                }, 300)
            },
            handleAthleteSearchSubmit(e) {
                e.preventDefault();
                this.searchAthletes();
            },
            async searchAthletes() {
                const response = await axios.post(`${process.env.VUE_APP_API}/athlete/getathletes`, 'searchCriteria=' + this.searchCriteria.replace("+", "%2b"));
                this.athletes = response.data;
            },
            toggleMenu(type) {
                switch (type) {
                    case 'tips':
                        this.showTips = !this.showTips;
                        this.isTipsPinned = false;
                        break;
                }
            },
            pinSidebar(sidebarType) {
                switch (sidebarType) {
                    case 'tips':
                        this.isTipsPinned = true;
                        break;
                }
            },
            unpinSidebar(sidebarType) {
                this.isTipsPinned = false;

                switch (sidebarType) {
                    case 'tips':
                        this.showTips = true;
                        break;
                }
            },
            lockType(type) {
                switch (type) {
                    case 'tips':
                        if (this.isTipsPinned) { return 'lock'; }
                        else { return 'lock_open'; }
                }
            }
        }
    });
</script>
