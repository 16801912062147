<template>
    <div class="slide-panel">
        <PinnedButtonGroup leftValue="0px" style="position: absolute; left: -34px; top: 10px;">
            <PinnedButton @click="$emit('toggleMenu','tips')" :isActive="isTips">
                <span class="material-symbols-outlined">tips_and_updates</span>
            </PinnedButton>
            <PinnedButton v-if="isRecommendationsOption" @click="$emit('toggleMenu','recommendations')" :isActive="isRecommendations">
                <span class="material-symbols-outlined"> school </span>
            </PinnedButton>
            <PinnedButton v-if="isNotesOption" @click="$emit('toggleMenu','notes')" :isActive="isNotes">
                <span class="material-symbols-outlined"> description </span>
            </PinnedButton>
        </PinnedButtonGroup>
        <slot></slot>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import PinnedButtonGroup from '../components/PinnedButtonGroup.vue';
import PinnedButton from '../components/PinnedButtons.vue';

export default defineComponent({
    components: {
        PinnedButton,
        PinnedButtonGroup,
    },

    props: {
        type: {
            type: String,
            default: ''
        },
        isRecommendationsOption: {
            type: Boolean,
            default: true
        },
        isNotesOption: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        isTips() { return this.type == 'tips' },
        isRecommendations() { return this.type == 'recommendations' },
        isNotes() { return this.type == 'notes' }
    }

    });
</script>

<style scoped>
    .slide-panel {
        position: absolute;
        top: 100px;
        right: 0;
        z-index: 9;
        width: 500px;
    }
</style>