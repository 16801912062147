<template>
    <div class="splash">
        <img alt="Cerebro" src="../assets/logo_m.png">
        <form class="splash-form">
            <div class="form-floating">
                <input class="form-control" v-model="password" id="site-password" type="password" placeholder="Enter Password" autocomplete="off" />
                <label for="site-password">Enter Password</label>
            </div>
            <div class="form-floating">
                <button class="splash-form__button btn btn-primary" type="button" @click="handlePasswordCheck">Enter Site</button>
            </div>
        </form>
    </div>
</template>

<style lang="scss">
.splash {
    display: flex;
    margin: 20px auto 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 500px;

    &-form {
        display: flex;
        flex-direction: column;
        margin: 10px 0;
        width: 280px;

        &__button {
            width: 100%;
            margin-top: 10px;
            justify-content: center;
        }
    }
}
</style>

<script>
    import Cookies from 'js-cookie';

    export default {
        name: 'SplashView',
        data() {
            return {
                password: '',
            };
        },
        methods: {
            handlePasswordCheck(e) {
                e.preventDefault();
                const encodedPW = window.btoa(this.password);
                if (encodedPW == process.env.VUE_APP_SITE_PASSWORD) {
                    Cookies.set('auth', encodedPW, { expires: 7 });
                    this.$router.push('/home');
                }
            },
        },
    }
</script>
