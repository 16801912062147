<template>
    <div class="cerebro-search" :class="searchType && `${searchType}-search`">
        <form class="cerebro-search__form" @submit="searchSubmit">
            <input class="cerebro-search__input" placeholder="search" type="text" v-model="inputField" @keyup="inputChange" />
            <button v-if="showFilters" type="button" class="cerebro-search__button cerebro-search__button--filter">
                <span class="cerebro-search__button-text">Filter</span>
            </button>
            <button class="cerebro-search__button cerebro-search__button--submit" type="submit">Search</button>
        </form>
    </div>
</template>

<style>
.cerebro-search {
    display: flex;
    padding: 10px 0;
}

.cerebro-search__form {
    display: flex;
}

.cerebro-search__input {
    display: inline-flex;
    align-self: center;
    height: 36px;
    padding: 7px 14px;
    border: 2px solid #0A2239;
    border-radius: 23px;
    font-size: 13px;
    font-weight: 600;
    line-height: 1;
}

.cerebro-search__button {
    position: relative;
    display: inline-flex;
	flex-wrap: wrap;
    align-items: baseline;
    height: 36px;
    margin-left: 5px;
    padding: 8px 14px;
    border-radius: 23px;
    background: var(--mn-white);
    font-size: 13px;
    line-height: 1;
}

.cerebro-search__button--filter::before {
    display: inline-flex;
    position: absolute;
    top: 50%;
    left: 15px;
    content: '\ef4f';
    width: 20px;
    height: 20px;
    color: var(--cerebro-blue-grey);
    font-family: 'Material Symbols Outlined';
    font-weight: 600;
    font-size: 20px;
    transform: translateY(-50%);
}

.cerebro-search__button-text {
    display: inline-flex;
    align-self: center;
    padding-left: 20px;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
}

.cerebro-search__button .material-symbols-outlined {
    font-size: 20px;
}

.cerebro-search__button-text,
.cerebro-search__button .material-symbols-outlined {
    color: var(--cerebro-blue-grey);
}

.cerebro-search__button--submit { display: none; }
</style>

<script>
    import { ref } from 'vue';

    export default {
        name: 'CerebroSearch',
        props: ['searchType', 'showFilters'],
        setup(props, context) {
            const inputField = ref('');
            const inputChange = () => context.emit('searchCriteriaUpdated', inputField.value);
            const searchSubmit = (e) => context.emit('searchSubmitted', e);

            return {
                inputChange,
                inputField,
                searchSubmit,
            };
        },
        emits: ['searchCriteriaUpdated', 'searchSubmitted']
    };
</script>
