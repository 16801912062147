<template>
    <div class="light-box">
        <slot></slot>
    </div>
</template>

<script>

</script>

<style scoped>
.light-box {
    position: absolute;
    margin: auto;
    top: 15%;
    left: 0;
    bottom: 0;
    right: 0;
    width: fit-content;
    z-index: 100;
}
</style>

