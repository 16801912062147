<template>
    <div class="row">
        <div class="col-6">
            <input type="range" class="form-range" min="-1" max="4" step="1" v-model="_answerValue" />
        </div>
        <div class="col-6">
            {{ this.displayText }}
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <h6 class="card-subtitle text-muted text-justify">
                <slot></slot>
            </h6>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from "vue";

    export default defineComponent({

        props: {
            answerValue: {
                type: Number,
                default: null
            },
            questionnaireType: {
                type: String,
                default: ''
            }
        },

        computed: {
            _answerValue: {
                get() { return this.answerValue; }, 
                set(value) {
                    this.displayText = this.displayTextArray.find(x => x.id == value)?.name;
                    this.$emit('update:answerValue', value);
                    this.$emit("saveQuestionnaire");
                }
            }
        },

        data() {
            return {

                displayText: '-',

                displayTextArray: [
                    { id: -1, name: '-' },
                    { id: 0, name: 'Never' },
                    { id: 1, name: 'Almost Never' },
                    { id: 2, name: 'Sometimes' },
                    { id: 3, name: 'Fairly Often' },
                    { id: 4, name: 'Very Often' },
                ],
            }   
        },

        async created() {
            this.displayText = this.displayTextArray.find(x => x.id == this.answerValue)?.name;
        }

    });
</script>