<template>

    <vue-title title="Cerebro: Administration" />

    <div class="container-fluid m-0 p-o mt-3">

        <div class="row">

            <div class="col-3 align-items-start">

                <AdminMenu activeMenuItem="educationmanagement" />

            </div>

            <div class="col-9">
                <div class="card border-primary text-muted">
                    <h6 class="text-center my-3">PLACEHOLDER FOR EDUCATION MANAGEMENT</h6>
                    <ul>
                        <li>list of education videos (from vimeo)</li>
                        <li>default view shows videos that need attention (i.e. without any tags)</li>
                        <li>search: general text search</li>
                        <li>search: tag-based search</li>
                        <li>filter: number of views; tag count; etc...</li>
                    </ul>
                </div>
            </div>

        </div>

    </div>

</template>

<style scoped src="../../../public/bootstrap-sketchy.css">
</style>

<script>
    import { defineComponent } from "vue";
    import AdminMenu from '../../components/admin-menu.vue'

    export default defineComponent({
        components: {
            AdminMenu
        }
});
</script>
