<template>

    <div v-if="$route.name !== 'splash'" class="container-fluid navbar-dark bg-primary">

        <div class="row align-middle align-items-center">

            <div class="col text-start ms-2">
                <a class="navbar-brand" href="/home"><strong>Cerebro</strong><span class="text-secondary">.</span>Coach</a>

                <span class="text-secondary" style="font-size: 1.5rem"><strong>Dashboard</strong></span>
            </div>

            <div class="col align-content-end text-end me-2">
                <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
                    <div class="container-fluid">

                        <div class="collapse navbar-collapse" id="navbarColor01">
                            <ul class="navbar-nav me-auto">
                                <li class="nav-item active">
                                    <router-link class="nav-link" to="/athletes">Clients</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link class="nav-link" to="/templates">Templates</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link class="nav-link" to="/education">Education</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link class="nav-link" to="/profile">Profile</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link class="nav-link" to="/admin/home">
                                        <span class="material-symbols-outlined">
                                            settings
                                        </span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link class="nav-link" to="/notifications">
                                        <span class="material-symbols-outlined">
                                            notifications
                                        </span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>

                    </div>
                </nav>
            </div>

        </div>
    </div>

    <router-view />

</template>

<style>
#app {
  font-family: poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

@import "../public/bootstrap-cerebro.css";
</style>
