<template>
    <div class="card-body text-start">
        <dl>
            <dt>Training Method Info</dt>
            <dd>Open the Education Tab and you'll receive recommendations based on the client's data. Click on a Training Method for more Information / Education on that Training Method</dd>

            <dt>Observations</dt>
            <dd>
                Observations in orange are getting old and it is recommended that you take an updated value for that observation.<br>
                Add new observations by pressing the plus (add) icon
            </dd>

            <dt>Create Program</dt>
            <dd>Drag a Training Method onto Client "Next Program" to Setup Training Method for that Client</dd>

            <dt>Athlete Questionnaire: Stress Questions</dt>
            <dd>
                <p>Rate each question 0 to 4</p>
                <ul style="list-style: none">
                    <li>0 = never</li>
                    <li>1 = almost never</li>
                    <li>2 = sometimes</li>
                    <li>3 = fairly often</li>
                    <li>4 = very often</li>
                </ul>
            </dd>
        </dl>
    </div>
</template>

<script>

</script>
