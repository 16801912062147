<template>
    <div class="container-fluid" style="position: relative;">
        <PinnedButtonGroup v-if="slidePanelsClosed" rightValue="0px">
            <PinnedButton @click="toggleMenu('tips')">
                <span class="material-symbols-outlined">tips_and_updates</span>
            </PinnedButton>
        </PinnedButtonGroup>

        <div class="row">
            <div class="col">
            </div>

            <div class="col-6">
                <form class="d-flex">
                    <input class="form-control me-sm-2" type="text" v-model="searchCriteria" @input="debounceSearchEducation" />
                    <button class="btn btn-secondary my-2 my-sm-0" type="submit" @click="searchEducation">Search</button>
                </form>
            </div>

            <div class="col">
            </div>
        </div>

        <div v-if="!isSidebarPinned" class="col-4">
            <transition name="slide-left">
                <SlidePanel v-if="showTips" type="tips" :isRecommendationsOption="false" :isNotesOption="false" @toggleMenu="toggleMenu($event)">
                    <div class="card border-primary mb-3">
                        <div class="card-header">
                            <h2>
                                Quick Tips
                                <span class="material-symbols-outlined btn btn-outline-primary btn-icon" @click="pinSidebar('tips')">lock_open</span>
                            </h2>
                        </div>
                        <QuickTipsEducationView />
                    </div>
                </SlidePanel>
            </transition>
        </div>
    </div>

    <div class="row" style="position: relative">

        <transition name="fade">
            <div v-if="isSearching" class="overlay">
                <img src="../assets/pre-loader-search.gif" />
            </div>
        </transition>

        <div :class="isSidebarPinned ? 'col-8' : 'col-12'">

            <template v-for="video in videos" :key="video.videoId">

                <div class="card bg-light m-2 float-sm-start" style="max-width: 18rem; min-width: 18rem;">
                    <div class="card-header">
                        <div class="row align-items-center">
                            <div class="col p-0">
                                <h5 class="m-0"><strong>{{ video.title }}</strong></h5>
                            </div>
                            <div class="col-2 p-0">
                                &nbsp;
                            </div>
                        </div>
                    </div>

                    <div class="card-body align-content-start">
                        <div style="padding:56.25% 0 0 0;position:relative;">
                            <iframe :src='video.embeddedSourceUrl' frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="{{ video.title }}"></iframe>
                        </div>
                    </div>
                </div>
            </template>

        </div>        

        <div v-if="isSidebarPinned" class="col-4">
            <div class="card border-primary mb-3">
                <div class="card-header">
                    <h2>
                        Quick Tips
                        <span class="material-symbols-outlined btn btn-outline-primary btn-icon" @click="unpinSidebar('tips')">lock_open</span>
                    </h2>
                </div>
                <QuickTipsEducationView />
            </div>
        </div>

    </div>
</template>

<script>
    import axios from 'axios';
    import { defineComponent } from "vue";
    import PinnedButtonGroup from '../components/PinnedButtonGroup.vue';
    import PinnedButton from '../components/PinnedButtons.vue';
    import SlidePanel from '../components/SlidePanel.vue';
    import QuickTipsEducationView from '../components/quick-tips/quick-tips-athletesView.vue'

    export default defineComponent({
        components: {
            PinnedButton,
            PinnedButtonGroup,
            SlidePanel,
            QuickTipsEducationView
        },

        data() {
            return {
                isDataChanged: false,
                isSearching: true,

                sidebarPinned: false,
                showTips: false,
                isTipsPinned: false,

                videos: null,
                searchCriteria: '',
                debounce: null
            }
        },

        async created() {            
            this.isSearching = true;
            //console.log(process.env.VUE_APP_API);
            const response = await axios.post(`${process.env.VUE_APP_API}/education/geteducation`, 'searchCriteria=' + this.searchCriteria.replace("+", "%2b"));
            this.videos = response.data;
            this.isSearching = false;
        },

        computed: {
            slidePanelsClosed() {
                return !this.showTips;
            },

            isSidebarPinned() {
                return this.isTipsPinned;
            }
        },

        methods: {
            debounceSearchEducation() {
                clearTimeout(this.debounce)
                this.debounce = setTimeout(() => {
                    this.searchEducation();
                }, 300)
            },
            async searchEducation() {
                const response = await axios.post(`${process.env.VUE_APP_API}/education/geteducation`, 'searchCriteria=' + this.searchCriteria.replace("+", "%2b"));
                this.videos = response.data;
            },
            toggleMenu(type) {
                switch (type) {
                    case 'tips':
                        this.showTips = !this.showTips;
                        this.isTipsPinned = false;
                        break;
                }
            },
            pinSidebar(sidebarType) {
                switch (sidebarType) {
                    case 'tips':
                        this.isTipsPinned = true;
                        break;
                }
            },
            unpinSidebar(sidebarType) {
                this.isTipsPinned = false;

                switch (sidebarType) {
                    case 'tips':
                        this.showTips = true;
                        break;
                }
            },
            lockType(type) {
                switch (type) {
                    case 'tips':
                        if (this.isTipsPinned) { return 'lock'; }
                        else { return 'lock_open'; }
                }
            }
        }
    });
</script>

<style scoped>
    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: 0.3;
        z-index: 99;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: all 0.4s ease-in-out;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .slide-left-enter-active,
    .slide-left-leave-active {
        transition: all 0.4s ease-in-out;
    }

    .slide-left-enter-from,
    .slide-left-leave-to {
        transform: translateX(500px);
    }
</style>
