<template>
    <div class="row mb-2">
        <button type="button" :class="['btn btn-outline-primary', { 'active': isHomeActive }]">
            <router-link class="nav-link m-0 p-0" to="/admin/home">
                <div class="container m-0 p-0">
                    <div class="row m-0 p-0">
                        <div class="col-2 align-middle">
                            <span v-if="isHomeActive" class="material-symbols-outlined mr-5" style="color: white">home</span>
                            <span v-if="!isHomeActive" class="material-symbols-outlined mr-5">home</span>
                        </div>
                        <div class='col-10 align-middle text-start ml-5 pl-5'>
                            <span v-if="isHomeActive" style="color: white">Admin Home</span>
                            <span v-if="!isHomeActive">Admin Home</span>
                        </div>
                    </div>
                </div>
            </router-link>
        </button>
    </div>

    <div class="row mb-2">
        <button type="button" :class="['btn btn-outline-primary', { 'active': isTrainingMethodTesterActive }]">
            <router-link class="nav-link m-0 p-0" to="/admin/training-method-tester">
                <div class="container m-0 p-0">
                    <div class="row m-0 p-0">
                        <div class="col-2 align-middle">
                            <span v-if="isTrainingMethodTesterActive" class="material-symbols-outlined mr-5" style="color: white">school</span>
                            <span v-if="!isTrainingMethodTesterActive" class="material-symbols-outlined mr-5">school</span>
                        </div>
                        <div class='col-10 align-middle text-start ml-5 pl-5'>
                            <span v-if="isTrainingMethodTesterActive" style="color: white">Training Method Tester</span>
                            <span v-if="!isTrainingMethodTesterActive">Training Method Tester</span>
                        </div>
                    </div>
                </div>
            </router-link>
        </button>
    </div>

    <div class="row mb-2">
        <button type="button" :class="['btn btn-outline-primary', { 'active': isEducationManagementActive }]">
            <router-link class="nav-link m-0 p-0" to="/admin/education-management">
                <div class="container m-0 p-0">
                    <div class="row m-0 p-0">
                        <div class="col-2 align-middle">
                            <span v-if="isEducationManagementActive" class="material-symbols-outlined mr-5" style="color: white">school</span>
                            <span v-if="!isEducationManagementActive" class="material-symbols-outlined mr-5">school</span>
                        </div>
                        <div class='col-10 align-middle text-start ml-5 pl-5'>
                            <span v-if="isEducationManagementActive" style="color: white">Education Management</span>
                            <span v-if="!isEducationManagementActive">Education Management</span>
                        </div>
                    </div>
                </div>
            </router-link>
        </button>
    </div>
</template>

<style scoped src="../../public/bootstrap-sketchy.css">
    .activeText {
        color: white;
    }
</style>

<script>
import { defineComponent } from "vue";

export default defineComponent({

    props: {
        activeMenuItem: {
            type: String,
            default: 'home'
        }
    },

    computed: {
        isHomeActive() { return this.activeMenuItem == 'home' },
        isTrainingMethodTesterActive() { return this.activeMenuItem == 'trainingmethodtester' },
        isEducationManagementActive() { return this.activeMenuItem == 'educationmanagement' }
    }

});
</script>
