<template>
    <button type="button" class="btn btn-primary" @click="open = true">
        <slot />
    </button>
    <Teleport to="body">
        <div v-if="open" class="cc-modal">
            <Transition name="cc-modal" appear>
                <article class="cc-add-athlete cc-modal__content">
                    <header class="cc-add-athlete__header">
                        <h1 class="cc-add-athlete__heading">New Athlete</h1>
                    </header>
                    <form class="cc-add-athlete__form" method="post">
                        <ul v-if="addAthleteErrors.length > 0" class="cc-add-athlete__errors-list">
                            <li class="cc-add-athlete__errors-item" v-for="(error, index) in addAthleteErrors"
                                :key="index">
                                <span v-html="error" />
                            </li>
                         </ul>
                        <div class="form-floating cc-add-athlete__field-wrapper">
                            <input id="first-name" class="form-control"
                                :class="{ 'is-invalid': v$.firstName.$errors.length }" type="text" name="first-name"
                                placeholder="First Name" v-model="firstName" />
                            <label for="first-name">First Name</label>
                            <ul v-if="v$.firstName.$errors.length" class="invalid-feedback cc-add-athlete__field-errors">
                                <li class="cc-add-athlete__field-error" v-for="(error, index) in v$.firstName.$errors"
                                    :key="index">
                                    {{ error.$message }}
                                </li>
                            </ul>
                        </div>
                        <div class="form-floating cc-add-athlete__field-wrapper">
                            <input id="last-name" class="form-control" :class="{ 'is-invalid': v$.lastName.$errors.length }"
                                type="text" name="last-name" placeholder="Last Name" v-model="lastName" />
                            <label for="last-name">Last Name</label>
                            <ul v-if="v$.lastName.$errors.length" class="invalid-feedback cc-add-athlete__field-errors">
                                <li class="cc-add-athlete__field-error" v-for="(error, index) in v$.lastName.$errors"
                                    :key="index">
                                    {{ error.$message }}
                                </li>
                            </ul>
                        </div>
                        <div class="form-floating has-validation cc-add-athlete__field-wrapper">
                            <input id="email" class="form-control" :class="{ 'is-invalid': v$.email.$errors.length }"
                                type="email" name="email" placeholder="Email" v-model="email" />
                            <label for="email">Email</label>
                            <ul v-if="v$.email.$errors.length" class="invalid-feedback cc-add-athlete__field-errors">
                                <li class="cc-add-athlete__field-error" v-for="(error, index) in v$.email.$errors"
                                    :key="index">
                                    {{ error.$message }}
                                </li>
                            </ul>
                        </div>
                        <div class="form-floating cc-add-athlete__field-wrapper">
                            <input id="phone" class="form-control" :class="{ 'is-invalid': v$.phone.$errors.length }"
                                type="tel" name="phone" placeholder="Phone Number" v-model="phone" />
                            <label for="phone">Phone Number</label>
                            <ul v-if="v$.phone.$errors.length" class="invalid-feedback cc-add-athlete__field-errors">
                                <li class="cc-add-athlete__field-error" v-for="(error, index) in v$.phone.$errors"
                                    :key="index">
                                    {{ error.$message }}
                                </li>
                            </ul>
                        </div>
                        <div class="form-floating cc-add-athlete__field-wrapper">
                            <select id="gender" class="form-select" name="gender" v-model="gender">
                                <option selected value="-1">Select Gender</option>
                                <option value="2">Male</option>
                                <option value="1">Female</option>
                                <option value="3">Other</option>
                                <option value="4">Prefer Not to Answer</option>
                            </select>
                            <label for="gender">Gender (optional)</label>
                        </div>
                        <div class="form-floating cc-add-athlete__field-wrapper">
                            <input id="birthdate" class="form-control" type="date" name="birthdate"
                                placeholder="Date of Birth" v-model="birthdate" />
                            <label for="birthdate">Birthdate (optional)</label>
                        </div>
                        <div v-if="emailAndPhoneProvided" class="cc-add-athlete__field-wrapper">
                            <h5>Communication Preference:</h5>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="communicationPreference"
                                    id="communicationPreferenceEmail" value="2" v-model="communicationPreference"
                                    :disabled="!emailAndPhoneProvided" />
                                <label class="form-check-label" for="communicationPreferenceEmail">
                                    Email
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="communicationPreference"
                                    id="communicationPreferencePhone" value="1" v-model="communicationPreference"
                                    :disabled="!emailAndPhoneProvided">
                                <label class="form-check-label" for="communicationPreferencePhone">
                                    Phone
                                </label>
                            </div>
                        </div>
                        <div class="cc-add-athlete__form-actions">
                            <button class="btn btn-secondary cc-add-athlete__form-action-btn" type="button" @click="reset">Cancel</button>
                            <button class="btn btn-primary cc-add-athlete__form-action-btn" type="button" @click="() => addAthlete()">Save &amp; Exit</button>
                            <button class="btn btn-primary cc-add-athlete__form-action-btn" type="button" @click="() => addAthlete(true)">Save &amp; Continue</button>
                        </div>
                    </form>
                </article>
            </Transition>
        </div>
    </Teleport>
</template>

<style>
.cc-modal-enter-active,
.cc-modal-leave-active {
    transition: opacity 350ms ease, transform 350ms ease;
}

.cc-modal-enter-from,
.cc-modal-leave-to {
    opacity: 0;
    transform: translateY(2.5%);
}

.cc-modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.15);
    z-index: 1000;
}

.cc-modal__content {
    width: 475px;
    padding: 10px 35px 25px;
    background-color: var(--bs-white);
    box-shadow: 6px 6px 0px var(--bs-secondary);
}

.cc-add-athlete__form {
    padding: 10px;
}

.cc-add-athlete__field-wrapper {
    margin-bottom: 12px;
}

.cc-add-athlete__field-errors {
    margin: 0;
    padding: 2px 0 0;
    list-style: none;
}

.cc-add-athlete__field-error {
    font-size: 0.75rem;

}

.cc-add-athlete__field-wrapper input[type="date"]::-webkit-calendar-picker-indicator {
    transform: translateY(-5px) scale(1.5);
}

.cc-add-athlete__form-actions {
    display: flex;
    justify-content: flex-end;
}

.cc-add-athlete__form-actions .cc-add-athlete__form-action-btn {
    margin-left: 10px;
}

.cc-add-athlete__form-actions .cc-add-athlete__form-action-btn:first-child {
    margin-left: 0;
}

.cc-add-athlete__errors-list {
    margin: 0 0 5px;
    padding: 2px 0 0;
    list-style: none;
}
.cc-add-athlete__errors-list {
    color: var(--bs-danger);
    font-size: var(--12px);
}
</style>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, email, required, requiredUnless } from '@vuelidate/validators';
import axios from 'axios';
import { phoneNumber, formatErrorMessage } from '@/util/validation-utils';

const initState = () => {
    return {
        open: false,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        gender: '-1',
        birthdate: '',
        communicationPreference: '-1',
        addAthleteErrors: [],
    };
};


export default {
    name: 'AddAthlete',
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return initState();
    },
    validations() {
        const validations = {
            firstName: {
                required: helpers.withMessage('First name is required', required),
            },
            lastName: {
                required: helpers.withMessage('Last name is required', required),
            },
            email: {
                email,
                requiredIfPhone: helpers.withMessage('Email address is required (if phone number not provided)',
                    requiredUnless(this.phone.length > 0)),
            },
        };

        if (this.phone.length) {
            return {
                ...validations,
                phone: {
                    phoneNumber: helpers.withMessage('Must be a valid mobile number', phoneNumber),
                },
            };
        }

        return {
            ...validations,
            phone: {
                requiredIfEmail: helpers.withMessage('Phone number is required (if email not provided)', requiredUnless(this.emailContainsInput)),
            },
        };
    },
    computed: {
        emailContainsInput() {
            return !!this.email.length;
        },
        emailAndPhoneProvided() {
            return !!this.email && !!this.phone;
        },
    },
    props: {
        type: String,
    },
    methods: {
        reset() {
            this.open = false
            this.v$.$reset();
            Object.assign(this.$data, initState());
        },
        async addAthlete(forwardToProfile = false) {
            const result = await this.v$.$validate();
            if (!result) return;

            const {
                firstName,
                lastName,
                email,
                phone,
                gender,
                birthdate,
                communicationPreference,
            } = this;

            try {
                const addAthleteRequest = await axios.post(
                    `${process.env.VUE_APP_API}/athlete/addathlete`, {
                        firstName,
                        lastName,
                        email,
                        mobile: phone,
                        communicationPreference: Number(communicationPreference),
                        gender: Number(gender),
                        birthdate,
                    });
                const {
                    athleteId,
                    isValidAthleteAdd,
                    validationErrors,
                } = addAthleteRequest.data;

                if (isValidAthleteAdd) {
                    this.reset();

                    if(forwardToProfile) {
                        console.log('forwarding to new profile...');
                        this.$router.push(`/athlete/${athleteId}`);
                    }
                    return;
                }

                if (!isValidAthleteAdd) {
                    validationErrors.forEach((error) => this.addAthleteErrors.push(formatErrorMessage(error)));
                }

            } catch (error) {
                console.error('Could not complete request: ', error);
            }
        },
        /*
        async emailQuestions() {
            console.log('email questions...');
        },
        async startAthleteOnboarding() {
            console.log('start athlete onboarding...');
        },
        */
    },
};
</script>
