<script>
    export default {
        name: 'NotificationShort',
        props: {
            icon: String,
            message: String,
            dueDate: String,
            isNew: String,
        }
    }
</script>

<template>

    <div class="container-fluid">

        <div class="row mb-2">
            <div class="col-2 align-middle">
                <span class="material-symbols-outlined">{{icon}}</span>
            </div>
            <div class="col-10 text-start">
                <div class="row">
                    <div class="col">{{message}}</div>
                </div>
                <div class="row">
                    <div class="col text-muted"><em>{{dueDate}}</em></div>
                </div>

            </div>
        </div>

    </div>

</template>

