
    import { defineComponent, PropType } from 'vue';

    // TODO: Typescript Sync
    // if we start getting strict with TS then we can probably look at making
    // these models more robust, and moving the interfaces and types into a
    // more logical location...
    interface Athlete {
        athleteId: number;
        fullName: string;
        trainingAgeText: string;
        clientFor: string;
    }

    export default defineComponent({
      name: 'AthleteSearchCard',
      props: {
        athlete: {
            type: Object as PropType<Athlete>,
            required: true,
        },
      }
    });
