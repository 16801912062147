<template>
    <div class="container-fluid m-0 p-o mt-3">

        <div class="row">

            <div class="col-3 align-items-start">

                <AdminMenu activeMenuItem="home" />

            </div>

            <div class="col-9">
                <div class="card border-primary">
                    <h6 class="text-center my-3">version 0.4</h6>
                </div>
            </div>

        </div>

        <div class="test">
            <span class="test-nested">this container will only be styled with white text and rebeccapurple background if scss is compiling correctly</span>
        </div>

    </div>
</template>

<style scoped src="../../../public/bootstrap-sketchy.css">
</style>

<style lang="scss">
.test {
    display: block;
    border: {
        width: 3px;
        color: pink;
        style: solid;
    }

    &-nested {
        background-color: rebeccapurple;
        color: white;
    }
}
</style>

<script>
    import { defineComponent } from "vue";
    import AdminMenu from '../../components/admin-menu.vue'

    export default defineComponent({
        components: {
            AdminMenu
        }
});
</script>
