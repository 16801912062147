import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useHead } from '@vueuse/head';
import Cookies from 'js-cookie';

import SplashView from '@/views/SplashView.vue'
import HomeView from '@/views/HomeView.vue'
import AthletesView from '@/views/AthletesView.vue'
import AthleteView from '@/views/AthleteView.vue'
import AthleteObservationsView from '@/views/AthleteObservationsView.vue'
import TrainingTemplates from '@/views/TrainingTemplates.vue';
import EducationView from '@/views/EducationView.vue'
import UserProfile from '@/views/UserProfile.vue';
import UserNotifications from '@/views/UserNotifications.vue';
import AdminHomeView from '@/views/admin/AdminHomeView.vue'
import AdminTrainingMethodTesterView from '@/views/admin/AdminTrainingMethodTesterView.vue'
import AdminEducationManagementView from '@/views/admin/AdminEducationManagementView.vue'
import RecommendationTesterView from '@/views/admin/RecommendationTesterView.vue'
import EditTrainingMethodView from '@/views/EditTrainingMethodView.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'splash',
        component: SplashView,
        meta: {
            title: 'Cerebro: Welcome',
        },
    },
    {
        path: '/home',
        name: 'home',
        component: HomeView,
        meta: {
            title: 'Cerebro: Home',
        },
    },
    {
        path: '/athletes',
        name: 'athletes',
        component: AthletesView,
        meta: {
            title: 'Cerebro: Athletes',
        },
    },
    {
        path: '/athlete/:athleteId',
        name: 'athlete',
        component: AthleteView,
        meta: {
            title: 'Cerebro: Athlete',
        }
    },
    {
        path: '/athlete-observations/:athleteId/:observation',
        name: 'athlete-observations',
        component: AthleteObservationsView
    },
    /* {
        path: '/education',
        name: 'education',
        component: EducationView
    }, */
    {
        path: '/templates',
        name: 'templates',
        component: TrainingTemplates,
        meta: {
            title: 'Cerebro: Training Templates',
        },
    },
    {
        path: '/education/:searchText?',
        name: 'education',
        component: EducationView,
        meta: {
            title: 'Cerebro: Education',
        },
    },
    {
        path: '/profile',
        name: 'profile',
        component: UserProfile,
        meta: {
            title: 'Cerebro: Profile',
        },
    },
    {
        path: '/admin/home',
        name: 'admin-home',
        component: AdminHomeView,
        meta: {
            title: 'Cerebro: Administration',
        },
    },
    {
        path: '/admin/training-method-tester',
        name: 'admin-training-method-tester',
        component: AdminTrainingMethodTesterView,
        meta: {
            title: 'Cerebro: Administration',
        },
    },
    {
        path: '/admin/education-management',
        name: 'admin-education-management',
        component: AdminEducationManagementView,
        meta: {
            title: 'Cerebro: Administration',
        },
    },
    {
        path: '/admin/recommendation-tester',
        name: 'admin-recommendation-tester',
        component: RecommendationTesterView,
        meta: {
            title: 'Cerebro: Recommendation Engine'
        }
    },
    {
        path: '/training-method/:trainingMethodId/edit',
        name: 'training-method-edit',
        component: EditTrainingMethodView,
        meta: {
            title: 'Cerebro: Training Method Edit'
        }
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: UserNotifications,
        meta: {
            title: 'Cerebro: Notifications',
        },
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '@/views/AboutView.vue'),
        meta: {
            title: 'Cerebro: About',
        },
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to) => {
    const auth = Cookies.get('auth');
    if (to.name != 'splash' && (auth === undefined || auth != process.env.VUE_APP_SITE_PASSWORD)) {
        console.log('auth? ', auth, auth === undefined, process.env.VUE_APP_SITE_PASSWORD);
        return { name: 'splash' };
    }

    useHead({
        title: `${to.meta?.title ?? 'Cerebro'}`,
    });
});

export default router;
