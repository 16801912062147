<template>
    <transition name="fade">
        <div v-if="isObservationEntry" class="overlay"></div>
    </transition>
    <div class="container-fluid m-0 p-0" style="position: relative;">
        <PinnedButtonGroup v-if="slidePanelsClosed" rightValue="0px">
            <PinnedButton @click="toggleMenu('tips')">
                <span class="material-symbols-outlined">tips_and_updates</span>
            </PinnedButton>
            <PinnedButton @click="toggleMenu('recommendations')">
                <span class="material-symbols-outlined"> school </span>
            </PinnedButton>
            <PinnedButton @click="toggleMenu('notes')">
                <span class="material-symbols-outlined"> description </span>
            </PinnedButton>
        </PinnedButtonGroup>
        

        <!-- top: athlete header (name and edit capabilities) -->
        <div class="row align-items-center">
            <div class="col text-start">
                <h1 class="ps-2">
                    <span v-if="!isEditAthleteName">
                        {{ athlete.fullName }}
                    </span>

                    <span v-if="isEditAthleteName" style="display: flex;">
                        <input type="text" class="form-control" style="max-width: 10rem;" id="inputAthleteFirstName" v-model="athlete.firstName" @change="flagChange" />
                        <input type="text" class="form-control" style="max-width: 10rem;" id="inputAthleteLastName" v-model="athlete.lastName" @change="flagChange" />
                        <button type="submit" class="btn" v-if="showSaveButtonForEditAthleteName" @click="saveAthleteName">
                            <span class="material-symbols-outlined">
                                save
                            </span>
                        </button>
                    </span>

                    <button type="submit" class="btn" v-if="!isEditAthleteName" @click="editAthleteName">
                        <span class="material-symbols-outlined">
                            edit
                        </span>
                    </button>
                </h1>
            </div>

            <div class="col-1">
                <span class="text-muted" v-if="isSavingAthlete">saving ...</span>
            </div>

            <div class="col-1">
                <button type="submit" class="btn" :class="{ 'btn-outline-danger': isDataChanged}" style="border: hidden" @click="saveAthlete">
                    <span class="material-symbols-outlined">
                        save
                    </span>
                </button>
            </div>
        </div>

        <div class="row">

            <div :class="isSidebarPinned ? 'col-8' : 'col-12'">

                <div class="container-fluid m-0 p-0">

                    <!-- athlete data row one -->
                    <div class="row">
                        <!-- athlete data input -->
                        <div class="col-6 mb-3">
                            <div class="card border-primary h-100">
                                <div class="card-header"><h2>Athlete Details</h2></div>
                                <div class="card-body">

                                    <div class="container my-3">

                                        <div class="row my-3 align-items-center">
                                            <div class="col-4">
                                                <h5 class="text-start">Birth Date</h5>
                                            </div>
                                            <div class="col">
                                                <input type="date" class="form-control" style="max-width: 10rem;" id="inputAthleteBirthdate" v-model="athlete.birthdate" @change="flagChange" />
                                            </div>
                                        </div>

                                        <div class="row my-3 align-items-center">
                                            <div class="col-4">
                                                <h5 class="text-start">Height (in cm)</h5>
                                            </div>
                                            <div class="col">
                                                <input type="text" class="form-control" style="max-width: 10rem;" id="inputAthleteHeightInCentimeters" v-model="athlete.heightInCentimeters" @change="flagChange" />
                                            </div>
                                        </div>

                                        <div class="row my-3 align-items-center">
                                            <div class="col-4">
                                                <h5 class="text-start">Gender</h5>
                                            </div>
                                            <div class="col">
                                                <select class="form-select" id="selectGender" v-model="athlete.gender">
                                                    <option v-for="gender in genders" :value="gender.id" :key="gender.id">
                                                        {{ gender.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row my-3 align-items-center">
                                            <div class="col-4">
                                                <h5 class="text-start">Fitness Goal</h5>
                                            </div>
                                            <div class="col">
                                                <select class="form-select" id="selectFitnessGoal" v-model="athlete.fitnessGoal" @change="recommendationInputChange">
                                                    <option v-for="fitnessGoal in fitnessGoals" :value="fitnessGoal.id" :key="fitnessGoal.id">
                                                        {{ fitnessGoal.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row my-3 align-items-center">
                                            <div class="col-4">
                                                <h5 class="text-start">Training Age</h5>
                                            </div>
                                            <div class="col">
                                                <select class="form-select" id="selectTrainingAge" v-model="athlete.trainingAge" @change="recommendationInputChange">
                                                    <option v-for="trainingAge in trainingAges" :value="trainingAge.id" :key="trainingAge.id">
                                                        {{ trainingAge.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <!-- observation data -->
                        <div class="col-6 mb-3">
                            <div class="card border-primary h-100">
                                <div class="card-header">
                                    <h2>
                                        Observations

                                        <button type="submit" class="btn btn-outline-primary btn-icon" v-if="isExistingAthlete" @click="viewAddObservationData">
                                            <span class="material-symbols-outlined">
                                                add
                                            </span>
                                        </button>
                                    </h2>
                                </div>
                                <div class="card-body">
                                    <div class="row my-0">
                                        <div class="col">
                                            <span class="text-muted">{{ new Date(mostRecentAthleteObservation?.recordedTimeUtc).toLocaleString() }}</span>
                                        </div>
                                    </div>

                                    <div class="row my-3">
                                        <div class="col-6">
                                            <h5 class="text-start">Weight (kg)</h5>
                                        </div>
                                        <div class="col-4">
                                            <p :class="{ 'text-warning': mostRecentAthleteObservation?.isWeightInKilogramsExpired}">{{ mostRecentAthleteObservation?.weightInKilograms }}</p>
                                        </div>
                                        <div class="col-1">
                                            <router-link class="nav-link p-0" :to="`/athlete-observations/${athlete.athleteId}/weightInKilograms`">
                                                <span class="material-symbols-outlined">
                                                    monitoring
                                                </span>
                                            </router-link>
                                        </div>
                                    </div>

                                    <div class="row my-3">
                                        <div class="col-6">
                                            <h5 class="text-start">BMI</h5>
                                        </div>
                                        <div class="col-4">
                                            <span>{{ mostRecentAthleteObservation?.bodyMassIndex }}</span>&nbsp;
                                            <span class="text-muted">({{ mostRecentAthleteObservation?.bodyMassIndexCategory }})</span>
                                        </div>
                                        <div class="col-1">
                                            <router-link class="nav-link p-0" :to="`/athlete-observations/${athlete.athleteId}/weightInKilograms`">
                                                <span class="material-symbols-outlined">
                                                    monitoring
                                                </span>
                                            </router-link>
                                        </div>
                                    </div>

                                    <div class="row my-3">
                                        <div class="col-6">
                                            <h5 class="text-start">Blood Pressure</h5>
                                        </div>
                                        <div class="col-4">
                                            <p :class="{ 'text-warning': mostRecentAthleteObservation?.isBloodPressureExpired}">{{ mostRecentAthleteObservation?.bloodPressureSystolic }} / {{ mostRecentAthleteObservation?.bloodPressureDiastolic }}</p>
                                        </div>
                                        <div class="col-1">
                                            <router-link class="nav-link p-0" :to="`/athlete-observations/${athlete.athleteId}/bloodPressure`">
                                                <span class="material-symbols-outlined">
                                                    monitoring
                                                </span>
                                            </router-link>
                                        </div>
                                    </div>

                                    <div class="row my-3">
                                        <div class="col-6">
                                            <h5 class="text-start">Resting Heart Rate</h5>
                                        </div>
                                        <div class="col-4">
                                            <p :class="{ 'text-warning': mostRecentAthleteObservation?.isRestingHeartRateExpired}">{{ mostRecentAthleteObservation?.restingHeartRate }}</p>
                                        </div>
                                        <div class="col-1">
                                            <router-link class="nav-link p-0" :to="`/athlete-observations/${athlete.athleteId}/restingHeartRate`">
                                                <span class="material-symbols-outlined">
                                                    monitoring
                                                </span>
                                            </router-link>
                                        </div>
                                    </div>

                                    <div class="row my-3">
                                        <div class="col-6">
                                            <h5 class="text-start">Stress Score</h5>
                                        </div>
                                        <div class="col-4">
                                            <span :class="{ 'text-warning': mostRecentAthleteObservation?.isStressScoreExpired}">{{ mostRecentAthleteObservation?.stressScore }}</span>&nbsp;
                                            <span class="text-muted">({{mostRecentAthleteObservation?.stressScoreCategory}})</span>
                                        </div>
                                        <div class="col-1">
                                            <router-link class="nav-link p-0" :to="`/athlete-observations/${athlete.athleteId}/stressScore`">
                                                <span class="material-symbols-outlined">
                                                    monitoring
                                                </span>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- athlete data row two -->
                    <div class="row">
                        <!-- program details -->
                        <div class="col-6 mb-3">
                            <div class="card border-primary h-100">
                                <div class="card-header"><h2>Program</h2></div>
                                <div class="card-body">
                                    <p class="text-muted text-start">PLACEHOLDER: CURRENT PROGRAM DETAILS</p>
                                    <hr />
                                    <p class="text-muted text-start">PLACEHOLDER: SET PROGRAM</p>
                                </div>
                            </div>
                        </div>

                        <!-- notifications, notes and calendar data -->
                        <div class="col-6 mb-3">
                            <div class="card border-primary h-100">
                                <div class="card-header"><h2>Notifications</h2></div>
                                <div class="card-body">
                                    <p class="text-muted text-start">PLACEHOLDER: NOTIFICATIONS</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- athlete questionnaire -->
                    <div class="row">
                        <div class="col-12">
                            <div class="card border-primary mb-3">
                                <div class="card-header">
                                    <h2>Athlete Questionnaire</h2>
                                </div>
                                <div class="card-body text-start">
                                    <div class="card-body">
                                        <div class="row align-middle">
                                            <div class="col-8 text-start">
                                                <h5 class="card-title">Stress Questionnaire</h5>
                                            </div>
                                            <div class="col-4 text-end align-middle">
                                                Athlete Stress Score: {{ athlete.athleteQuestionnaireStressDto?.questionnaireResult }}
                                            </div>
                                        </div>
                                        
                                        <hr />

                                        <p>In the last month, how often have you ...</p>

                                        <div class="container-fluid m-0 p-0">

                                            <div class="row mb-2 pb-4">
                                                <div class="col-6">
                                                    <QuestionnaireQuestion v-model:answerValue="athlete.athleteQuestionnaireStressDto.feltUpsetBecauseOfSomethingHappeningUnexpectedly" @saveQuestionnaire="saveQuestionnaire('stress')">... felt upset because of something that happened unexpectedly?</QuestionnaireQuestion>
                                                </div>

                                                <div class="col-6">
                                                    <QuestionnaireQuestion v-model:answerValue="athlete.athleteQuestionnaireStressDto.feltOutOfControlOfTheImportantThingsInYourLife" @saveQuestionnaire="saveQuestionnaire('stress')">... felt out of control of the important things in your life?</QuestionnaireQuestion>
                                                </div>
                                            </div>

                                            <div class="row mb-2 pb-4">
                                                <div class="col-6">
                                                    <QuestionnaireQuestion v-model:answerValue="athlete.athleteQuestionnaireStressDto.feltNervousAndStressed" @saveQuestionnaire="saveQuestionnaire('stress')">... felt nervous and stressed?</QuestionnaireQuestion>
                                                </div>

                                                <div class="col-6">
                                                    <QuestionnaireQuestion v-model:answerValue="athlete.athleteQuestionnaireStressDto.feltConfidentAboutYourAbilityToHandlePersoanlProblemsThatHaveArisen" @saveQuestionnaire="saveQuestionnaire('stress')">... felt confident about your ability to handle personal problems that have arisen?</QuestionnaireQuestion>
                                                </div>
                                            </div>

                                            <div class="row mb-2 pb-4">
                                                <div class="col-6">
                                                    <QuestionnaireQuestion v-model:answerValue="athlete.athleteQuestionnaireStressDto.feltThatThingsWereGoingYourWay" @saveQuestionnaire="saveQuestionnaire('stress')">... felt that things were going your way?</QuestionnaireQuestion>
                                                </div>

                                                <div class="col-6">
                                                    <QuestionnaireQuestion v-model:answerValue="athlete.athleteQuestionnaireStressDto.feltOverwhelmedWithAllTheThingsThatYouHadToDo" @saveQuestionnaire="saveQuestionnaire('stress')">... felt overwhelmed with all the things that you had to do?</QuestionnaireQuestion>
                                                </div>
                                            </div>

                                            <div class="row mb-2 pb-4">
                                                <div class="col-6">
                                                    <QuestionnaireQuestion v-model:answerValue="athlete.athleteQuestionnaireStressDto.beenAbleToEffectivelyControlIrritationsInYourLife" @saveQuestionnaire="saveQuestionnaire('stress')">... been able to effectively control irritations in your life?</QuestionnaireQuestion>
                                                </div>

                                                <div class="col-6">
                                                    <QuestionnaireQuestion v-model:answerValue="athlete.athleteQuestionnaireStressDto.feltThatYourWereOnTopOfThings" @saveQuestionnaire="saveQuestionnaire('stress')">... felt that you were on top of things?</QuestionnaireQuestion>
                                                </div>
                                            </div>

                                            <div class="row mb-2 pb-4">
                                                <div class="col-6">
                                                    <QuestionnaireQuestion v-model:answerValue="athlete.athleteQuestionnaireStressDto.beenAngeredBecauseOfThingsThatHappenedThatWereOutsideOfYourControl" @saveQuestionnaire="saveQuestionnaire('stress')">... been angered because of things that happened that were outside of your control?</QuestionnaireQuestion>
                                                </div>

                                                <div class="col-6">
                                                    <QuestionnaireQuestion v-model:answerValue="athlete.athleteQuestionnaireStressDto.feltDifficultiesWerePilingUpSoHighThatYouCouldNotOvercomeThem" @saveQuestionnaire="saveQuestionnaire('stress')">... felt difficulties were piling up so high that you could not overcome them?</QuestionnaireQuestion>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="card-body">
                                        <div class="row align-middle">
                                            <div class="col-8 text-start">
                                                <h5 class="card-title">Sleep Questionnaire</h5>
                                            </div>
                                            <div class="col-4 text-end align-middle">
                                                Athlete Sleep Score: {{ athlete.athleteQuestionnaireSleepDto.questionnaireResult }}
                                            </div>
                                        </div>

                                        <hr />

                                        <div class="container-fluid m-0 p-0">

                                            <div class="row mb-2 pb-4">
                                                <div class="col-6">
                                                    <QuestionnaireQuestion v-model:answerValue="athlete.athleteQuestionnaireSleepDto.difficultyFallingAsleep" @saveQuestionnaire="saveQuestionnaire('sleep')">I have difficulty falling asleep.</QuestionnaireQuestion>
                                                </div>

                                                <div class="col-6">
                                                    <QuestionnaireQuestion v-model:answerValue="athlete.athleteQuestionnaireSleepDto.wakeDuringTheNight" @saveQuestionnaire="saveQuestionnaire('sleep')">I wake up during the night.</QuestionnaireQuestion>
                                                </div>
                                            </div>

                                            <div class="row mb-2 pb-4">
                                                <div class="col-6">
                                                    <QuestionnaireQuestion v-model:answerValue="athlete.athleteQuestionnaireSleepDto.difficultyGettingBackToSleep" @saveQuestionnaire="saveQuestionnaire('sleep')">I have difficulty getting back to sleep once I wake up in the middle of the night.</QuestionnaireQuestion>
                                                </div>

                                                <div class="col-6">
                                                    <QuestionnaireQuestion v-model:answerValue="athlete.athleteQuestionnaireSleepDto.wakeUpEasilyBecauseOfNoise" @saveQuestionnaire="saveQuestionnaire('sleep')">I wake up easily because of noise.</QuestionnaireQuestion>
                                                </div>
                                            </div>

                                            <div class="row mb-2 pb-4">
                                                <div class="col-6">
                                                    <QuestionnaireQuestion v-model:answerValue="athlete.athleteQuestionnaireSleepDto.tossAndTurn" @saveQuestionnaire="saveQuestionnaire('sleep')">I toss and turn. (e.g. I wake in the morning with tangled sheets and bedding)</QuestionnaireQuestion>
                                                </div>

                                                <div class="col-6">
                                                    <QuestionnaireQuestion v-model:answerValue="athlete.athleteQuestionnaireSleepDto.wakeUpFeelingRefreshedAwakeAndAlert" @saveQuestionnaire="saveQuestionnaire('sleep')">I wake up in the morning feeling refreshed, awake, and alert.</QuestionnaireQuestion>
                                                </div>
                                            </div>

                                            <div class="row mb-2 pb-4">
                                                <div class="col-6">
                                                    <QuestionnaireQuestion v-model:answerValue="athlete.athleteQuestionnaireSleepDto.requireAlarmToWakeUp" @saveQuestionnaire="saveQuestionnaire('sleep')">I need an alarm to wake up.</QuestionnaireQuestion>
                                                </div>

                                                <div class="col-6">
                                                    <QuestionnaireQuestion v-model:answerValue="athlete.athleteQuestionnaireSleepDto.wouldLikeToSleepMoreAfterWakingUp" @saveQuestionnaire="saveQuestionnaire('sleep')">I would like to sleep more after waking up.</QuestionnaireQuestion>
                                                </div>
                                            </div>

                                            <div class="row mb-2 pb-4">
                                                <div class="col-6">
                                                    <QuestionnaireQuestion v-model:answerValue="athlete.athleteQuestionnaireSleepDto.difficultyGettingOutOfBedInTheMorning" @saveQuestionnaire="saveQuestionnaire('sleep')">I have difficulty getting out of bed in the morning.</QuestionnaireQuestion>
                                                </div>

                                                <div class="col-6">
                                                    <QuestionnaireQuestion v-model:answerValue="athlete.athleteQuestionnaireSleepDto.sleepMoreThanSevenHoursEachNight" @saveQuestionnaire="saveQuestionnaire('sleep')">I sleep more than seven hours each night.</QuestionnaireQuestion>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="card-body">
                                        <div class="row align-middle">
                                            <div class="col-8 text-start">
                                                <h5 class="card-title">Training Age Questionnaire</h5>
                                            </div>
                                            <div class="col-4 text-end align-middle">
                                                Athlete Training Age Score (AVG): {{ athlete.athleteQuestionnaireTrainingAgeDto.trainingAgeTypeByAverageCalculationDisplay }}
                                            </div>
                                        </div>
                                        <div class="row align-middle">
                                            <div class="col-8 text-start"></div>
                                            <div class="col-4 text-end align-middle">
                                                Athlete Training Age Score (PLR): {{ athlete.athleteQuestionnaireTrainingAgeDto.trainingAgeTypeByPluralityCalculationDisplay }}
                                            </div>
                                        </div>

                                        <hr />

                                        <div class="row mb-2 pb-4">
                                            <div class="col-8">
                                                Has your client trained with weights in a gym before?
                                            </div>

                                            <div class="col-4">
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="athlete.athleteQuestionnaireTrainingAgeDto.trainedWithWeightsInAGymBefore" @change="saveQuestionnaire('trainingAge')">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-2 pb-4" v-if="athlete.athleteQuestionnaireTrainingAgeDto.trainedWithWeightsInAGymBefore">
                                            <div class="col-8">
                                                Has your client trained consistently for more than <strong>6 months</strong> with a structured program?
                                            </div>

                                            <div class="col-4">
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="athlete.athleteQuestionnaireTrainingAgeDto.trainedConsistentlyForMoreThanSixMonthsStructuredProgram" @change="saveQuestionnaire('trainingAge')">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-2 pb-4" v-if="athlete.athleteQuestionnaireTrainingAgeDto.trainedConsistentlyForMoreThanSixMonthsStructuredProgram">
                                            <div class="col-8">
                                                Has your client trained consistently for more than <strong>18 months</strong> with a structured program?
                                            </div>

                                            <div class="col-4">
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="athlete.athleteQuestionnaireTrainingAgeDto.trainedConsistentlyForMoreThanEighteenMonthsStructuredProgram" @change="saveQuestionnaire('trainingAge')">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-2 pb-4" v-if="athlete.athleteQuestionnaireTrainingAgeDto.trainedConsistentlyForMoreThanEighteenMonthsStructuredProgram">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-8">
                                                        Enter current max <strong>HIGH BAR BACK SQUAT</strong>
                                                    </div>
                                                    <div class="col-4 text-muted">
                                                        {{ athlete.athleteQuestionnaireTrainingAgeDto.highBarBackSquatTrainingAgeDisplay }}
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-3">
                                                        Reps:
                                                        <select class="form-select" id="selectHighBarBackSquatInputRepetitions" v-model="athlete.athleteQuestionnaireTrainingAgeDto.highBarBackSquatOneRepMax.inputRepetitions" @change="saveQuestionnaire('trainingAge')">
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-3">
                                                        Weight (in KG):
                                                        <input type="text" class="form-control" style="max-width: 10rem;" id="inputHighBarBackSquatInputWeightInKilograms" v-model="athlete.athleteQuestionnaireTrainingAgeDto.highBarBackSquatOneRepMax.inputWeightInKilograms" @change="saveQuestionnaire('trainingAge')" />
                                                    </div>

                                                    <div class="col-6">
                                                        One Rep Max: {{ athlete.athleteQuestionnaireTrainingAgeDto.highBarBackSquatOneRepMax.weightInKilograms }}
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-6 text-muted">
                                                        Input Data
                                                    </div>

                                                    <div class="col-6 text-muted">
                                                        One Rep Max
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-2 pb-4" v-if="athlete.athleteQuestionnaireTrainingAgeDto.trainedConsistentlyForMoreThanEighteenMonthsStructuredProgram">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-8">
                                                        Enter current max <strong>DEADLIFT</strong>
                                                    </div>
                                                    <div class="col-4 text-muted">
                                                        {{ athlete.athleteQuestionnaireTrainingAgeDto.deadliftTrainingAgeDisplay }}
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-3">
                                                        Reps:
                                                        <select class="form-select" id="selectDeadliftInputRepetitions" v-model="athlete.athleteQuestionnaireTrainingAgeDto.deadliftOneRepMax.inputRepetitions" @change="saveQuestionnaire('trainingAge')">
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-3">
                                                        Weight (in KG):
                                                        <input type="text" class="form-control" style="max-width: 10rem;" id="inputDeadliftInputWeightInKilograms" v-model="athlete.athleteQuestionnaireTrainingAgeDto.deadliftOneRepMax.inputWeightInKilograms" @change="saveQuestionnaire('trainingAge')" />
                                                    </div>

                                                    <div class="col-6">
                                                        One Rep Max: {{ athlete.athleteQuestionnaireTrainingAgeDto.deadliftOneRepMax.weightInKilograms }}
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-6 text-muted">
                                                        Input Data
                                                    </div>

                                                    <div class="col-6 text-muted">
                                                        One Rep Max
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-2 pb-4" v-if="athlete.athleteQuestionnaireTrainingAgeDto.trainedConsistentlyForMoreThanEighteenMonthsStructuredProgram">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-8">
                                                        Enter current max <strong>BENCH PRESS</strong>
                                                    </div>
                                                    <div class="col-4 text-muted">
                                                        {{ athlete.athleteQuestionnaireTrainingAgeDto.benchPressTrainingAgeDisplay }}
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-3">
                                                        Reps:
                                                        <select class="form-select" id="selectBenchPressInputRepetitions" v-model="athlete.athleteQuestionnaireTrainingAgeDto.benchPressOneRepMax.inputRepetitions" @change="saveQuestionnaire('trainingAge')">
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-3">
                                                        Weight (in KG):
                                                        <input type="text" class="form-control" style="max-width: 10rem;" id="inputBenchPressInputWeightInKilograms" v-model="athlete.athleteQuestionnaireTrainingAgeDto.benchPressOneRepMax.inputWeightInKilograms" @change="saveQuestionnaire('trainingAge')" />
                                                    </div>

                                                    <div class="col-6">
                                                        One Rep Max: {{ athlete.athleteQuestionnaireTrainingAgeDto.benchPressOneRepMax.weightInKilograms }}
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-6 text-muted">
                                                        Input Data
                                                    </div>

                                                    <div class="col-6 text-muted">
                                                        One Rep Max
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-2 pb-4" v-if="athlete.athleteQuestionnaireTrainingAgeDto.trainedConsistentlyForMoreThanEighteenMonthsStructuredProgram">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-8">
                                                        Enter current max <strong>FRONT SQUAT</strong>
                                                    </div>
                                                    <div class="col-4 text-muted">
                                                        {{ athlete.athleteQuestionnaireTrainingAgeDto.frontSquatTrainingAgeDisplay }}
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-3">
                                                        Reps:
                                                        <select class="form-select" id="selectFrontSquatInputRepetitions" v-model="athlete.athleteQuestionnaireTrainingAgeDto.frontSquatOneRepMax.inputRepetitions" @change="saveQuestionnaire('trainingAge')">
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-3">
                                                        Weight (in KG):
                                                        <input type="text" class="form-control" style="max-width: 10rem;" id="inputFrontSquatInputWeightInKilograms" v-model="athlete.athleteQuestionnaireTrainingAgeDto.frontSquatOneRepMax.inputWeightInKilograms" @change="saveQuestionnaire('trainingAge')" />
                                                    </div>

                                                    <div class="col-6">
                                                        One Rep Max: {{ athlete.athleteQuestionnaireTrainingAgeDto.frontSquatOneRepMax.weightInKilograms }}
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-6 text-muted">
                                                        Input Data
                                                    </div>

                                                    <div class="col-6 text-muted">
                                                        One Rep Max
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-2 pb-4" v-if="athlete.athleteQuestionnaireTrainingAgeDto.trainedConsistentlyForMoreThanEighteenMonthsStructuredProgram">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-8">
                                                        Enter current max <strong>OVERHEAD PRESS</strong>
                                                    </div>
                                                    <div class="col-4 text-muted">
                                                        {{ athlete.athleteQuestionnaireTrainingAgeDto.overheadPressTrainingAgeDisplay }}
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-3">
                                                        Reps:
                                                        <select class="form-select" id="selectOverheadPressInputRepetitions" v-model="athlete.athleteQuestionnaireTrainingAgeDto.overheadPressOneRepMax.inputRepetitions" @change="saveQuestionnaire('trainingAge')">
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-3">
                                                        Weight (in KG):
                                                        <input type="text" class="form-control" style="max-width: 10rem;" id="inputOverheadPressInputWeightInKilograms" v-model="athlete.athleteQuestionnaireTrainingAgeDto.overheadPressOneRepMax.inputWeightInKilograms" @change="saveQuestionnaire('trainingAge')" />
                                                    </div>

                                                    <div class="col-6">
                                                        One Rep Max: {{ athlete.athleteQuestionnaireTrainingAgeDto.overheadPressOneRepMax.weightInKilograms }}
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-6 text-muted">
                                                        Input Data
                                                    </div>

                                                    <div class="col-6 text-muted">
                                                        One Rep Max
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-2 pb-4" v-if="athlete.athleteQuestionnaireTrainingAgeDto.trainedConsistentlyForMoreThanEighteenMonthsStructuredProgram">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-8">
                                                        Enter current max <strong>INCLINE PRESS</strong>
                                                    </div>
                                                    <div class="col-4 text-muted">
                                                        {{ athlete.athleteQuestionnaireTrainingAgeDto.inclinePressTrainingAgeDisplay }}
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-3">
                                                        Reps:
                                                        <select class="form-select" id="selectInclinePressInputRepetitions" v-model="athlete.athleteQuestionnaireTrainingAgeDto.inclinePressOneRepMax.inputRepetitions" @change="saveQuestionnaire('trainingAge')">
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-3">
                                                        Weight (in KG):
                                                        <input type="text" class="form-control" style="max-width: 10rem;" id="inputInclinePressInputWeightInKilograms" v-model="athlete.athleteQuestionnaireTrainingAgeDto.inclinePressOneRepMax.inputWeightInKilograms" @change="saveQuestionnaire('trainingAge')" />
                                                    </div>

                                                    <div class="col-6">
                                                        One Rep Max: {{ athlete.athleteQuestionnaireTrainingAgeDto.inclinePressOneRepMax.weightInKilograms }}
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-6 text-muted">
                                                        Input Data
                                                    </div>

                                                    <div class="col-6 text-muted">
                                                        One Rep Max
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-2 pb-4" v-if="athlete.athleteQuestionnaireTrainingAgeDto.trainedConsistentlyForMoreThanEighteenMonthsStructuredProgram">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-8">
                                                        Enter current max <strong>BODYWEIGHT DIPS</strong>
                                                    </div>
                                                    <div class="col-4 text-muted">
                                                        {{ athlete.athleteQuestionnaireTrainingAgeDto.dipsBodyweightTrainingAgeDisplay }}
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12">
                                                        Max Reps:
                                                        <input type="text" class="form-control" style="max-width: 10rem;" id="inputDipsBodyweightInputMaxReps" v-model="athlete.athleteQuestionnaireTrainingAgeDto.dipsBodyweightMaxReps.repetitions" @change="saveQuestionnaire('trainingAge')" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-2 pb-4" v-if="athlete.athleteQuestionnaireTrainingAgeDto.trainedConsistentlyForMoreThanEighteenMonthsStructuredProgram">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-8">
                                                        Enter current max <strong>BODYWEIGHT CHINUPS</strong>
                                                    </div>
                                                    <div class="col-4 text-muted">
                                                        {{ athlete.athleteQuestionnaireTrainingAgeDto.chinupsBodyweightTrainingAgeDisplay }}
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12">
                                                        Max Reps:
                                                        <input type="text" class="form-control" style="max-width: 10rem;" id="inputChinupsBodyweightInputMaxReps" v-model="athlete.athleteQuestionnaireTrainingAgeDto.chinupsBodyweightMaxReps.repetitions" @change="saveQuestionnaire('trainingAge')" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div v-if="isSidebarPinned" class="col-4">
                <div v-if="isTipsPinned" class="card border-primary mb-3">
                    <div class="card-header">
                        <h2>
                            Quick Tips
                            <span class="material-symbols-outlined btn btn-outline-primary btn-icon" @click="unpinSidebar('tips')">lock</span>
                        </h2>
                    </div>
                    <QuickTipsAthleteView />
                </div>

                <div v-if="isRecommendationsPinned" class="card border-primary mb-3">
                    <div class="card-header">
                        <h2>
                            Recommendations
                            <span class="material-symbols-outlined btn btn-outline-primary btn-icon" @click="unpinSidebar('recommendations')">lock</span>
                        </h2>
                    </div>
                    <div class="card-body">
                        <table class="table table-hover">
                            <tbody>
                                <template v-for="recommendation in recommendations" :key="recommendation.id">
                                    <tr>
                                        <th class="text-start" scope="row">
                                            {{ recommendation.trainingMethodName }}
                                        </th>
                                        <td class="text-muted">
                                            <router-link class="nav-link p-0" :to="`/education/${recommendation.trainingMethodName}`">
                                                <span class="material-symbols-outlined">
                                                    arrow_circle_right
                                                </span>
                                            </router-link>
                                        </td>
                                        <td class="text-muted" style="cursor: pointer" @click="linkTrainingMethod">
                                            <span class="material-symbols-outlined">
                                                link
                                            </span>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div v-if="isNotesPinned" class="card border-primary mb-3">
                    <div class="card-header">
                        <h2>
                            Notes
                            <span class="material-symbols-outlined btn btn-outline-primary btn-icon" @click="unpinSidebar('notes')">lock</span>
                        </h2>
                    </div>
                    <div class="card-body text-start">
                        <dl>
                            <dt>2022-09-29</dt>
                            <dd>Limited shoulder use due to footy injury sustained over the weekend</dd>

                            <dt>2022-07-01</dt>
                            <dd>Client is interested in more endurance for playing footy at a higher grade</dd>
                        </dl>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!isSidebarPinned" class="col-4">
            <transition name="slide-left">
                <SlidePanel v-if="showTips" type="tips" @toggleMenu="toggleMenu($event)">
                    <div class="card border-primary mb-3">
                        <div class="card-header">
                            <h2>
                                Quick Tips
                                <span class="material-symbols-outlined btn btn-outline-primary btn-icon" @click="pinSidebar('tips')">lock_open</span>
                            </h2>
                        </div>
                        <QuickTipsAthleteView />
                    </div>
                </SlidePanel>
            </transition>

            <transition name="slide-left">
                <SlidePanel v-if="showRecommendations" type="recommendations" @toggleMenu="toggleMenu($event)">
                    <div class="card border-primary mb-3">
                        <div class="card-header">
                            <h2>
                                Recommendations
                                <span class="material-symbols-outlined btn btn-outline-primary btn-icon" @click="pinSidebar('recommendations')">lock_open</span>
                            </h2>
                        </div>
                        <div class="card-body">
                            <table class="table table-hover">
                                <tbody>
                                    <template v-for="recommendation in recommendations" :key="recommendation.id">
                                        <tr>
                                            <th class="text-start" scope="row">
                                                {{ recommendation.trainingMethodName }}
                                            </th>
                                            <td class="text-muted">
                                                <router-link class="nav-link p-0" :to="`/education/${recommendation.trainingMethodName}`">
                                                    <span class="material-symbols-outlined">
                                                        arrow_circle_right
                                                    </span>
                                                </router-link>
                                            </td>
                                            <td class="text-muted" style="cursor: pointer" @click="linkTrainingMethod">
                                                <span class="material-symbols-outlined">
                                                    link
                                                </span>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </SlidePanel>
            </transition>

            <transition name="slide-left">
                <SlidePanel v-if="showNotes" type="notes" @toggleMenu="toggleMenu($event)">
                    <div class="card border-primary mb-3">
                        <div class="card-header">
                            <h2>
                                Notes
                                <span class="material-symbols-outlined btn btn-outline-primary btn-icon" @click="pinSidebar('notes')">lock_open</span>
                            </h2>
                        </div>
                        <div class="card-body text-start">
                            <dl>
                                <dt>2022-09-29</dt>
                                <dd>Limited shoulder use due to footy injury sustained over the weekend</dd>

                                <dt>2022-07-01</dt>
                                <dd>Client is interested in more endurance for playing footy at a higher grade</dd>
                            </dl>
                        </div>
                    </div>
                </SlidePanel>
            </transition>
        </div>
    </div>

    <transition name="fade">
        <LightBox v-if="isObservationEntry">
            <div class="container-fluid">
                <div class="row">

                    <!-- athlete: Add Observation Data -->
                    <div class="col-12" style="padding: 0;">
                        <div class="card border-primary mb-3" style="margin-bottom: 0;">
                            <div class="card-header"><h2>Athlete Observations</h2></div>
                            <div class="card-body">
                                <div class="row my-3">
                                    <div class="col-8">
                                        <h4 class="text-start">Weight (in Kilograms)</h4>
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" style="max-width: 10rem;" id="inputAthleteWeightInKilograms" v-model="athleteObservation.weightInKilograms" @change="flagObservationChange" />
                                    </div>
                                </div>

                                <div class="row my-3">
                                    <div class="col-8">
                                        <h4 class="text-start">Blood Pressure</h4>
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" style="max-width: 10rem;" id="inputAthleteBloodPressureSystolic" v-model="athleteObservation.bloodPressureSystolic" @change="flagObservationChange" />
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" style="max-width: 10rem;" id="inputAthleteBloodPressureDiastolic" v-model="athleteObservation.bloodPressureDiastolic" @change="flagObservationChange" />
                                    </div>
                                </div>

                                <div class="row my-3">
                                    <div class="col-8">
                                        <h4 class="text-start">Resting Heart Rate</h4>
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" style="max-width: 10rem;" id="inputAthleteRestingHeartRate" v-model="athleteObservation.restingHeartRate" @change="flagObservationChange" />
                                    </div>
                                </div>

                                <div class="row my-3">
                                    <div class="col-8">
                                        <h4 class="text-start">Stress Score</h4>
                                    </div>
                                    <div class="col">
                                        <input type="range" class="form-range" min="1" max="10" step="1" id="inputAthleteStressScore" v-model="athleteObservation.stressScore" @change="flagObservationChange" />
                                    </div>
                                    <div class="col-1">
                                        <small class="text-muted">{{ athleteObservation.stressScore }}</small>
                                    </div>
                                </div>

                                <div class="row my-3">
                                    <div class="col-5">&nbsp;</div>
                                    <div class="col-2">
                                        <span class="material-symbols-outlined btn btn-primary btn-icon mr-5" @click="saveObservationData">save</span>
                                        <span class="material-symbols-outlined btn btn-primary btn-icon ml-5" @click="cancelObservationData">cancel</span>
                                    </div>
                                    <div class="col-5">&nbsp;</div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </LightBox>
    </transition>
    
</template>

<script>
    import axios from 'axios';
    import { defineComponent } from "vue";
    import PinnedButtonGroup from '../components/PinnedButtonGroup.vue';
    import PinnedButton from '../components/PinnedButtons.vue';
    import SlidePanel from '../components/SlidePanel.vue';
    import LightBox from '../components/LightBox.vue';
    import QuickTipsAthleteView from '../components/quick-tips/quick-tips-athleteView.vue'
    import QuestionnaireQuestion from '../components/questionnaire-question.vue'
    import { useHead } from '@vueuse/head';

    export default defineComponent({
        components: {
            PinnedButton,
            PinnedButtonGroup,
            SlidePanel,
            LightBox,
            QuickTipsAthleteView,
            QuestionnaireQuestion
        },

        data() {
            return {
                isObservationEntry: false,
                isObservationDataChanged: false,
                isDataChanged: false,
                isSavingAthlete: false, 
                isEditAthleteName: false,

                showTips: false,
                showRecommendations: false,
                showNotes: false,

                isTipsPinned: false,
                isRecommendationsPinned: false,
                isNotesPinned: false,

                athlete: {
                    id: 0,
                    firstName: '',
                    lastName: '',
                    fullName: '',
                    athleteQuestionnaireSleepDto: {},
                    athleteQuestionnaireStressDto: {},
                    athleteQuestionnaireTrainingAgeDto: {}
                },
                mostRecentAthleteObservation: null,
                recommendations: null,
                athleteObservation: {
                    athleteId: this.$route.params.athleteId,
                    recordedTimeUtc: null
                },

                genders: [
                    { id: -1, name: '-' },
                    { id: 2, name: 'Male' },
                    { id: 1, name: 'Female' },
                    { id: 3, name: 'Other' },
                    { id: 4, name: 'Prefer Not to Provide' },
                ],
                fitnessGoals: [
                    { id: null, name: '-' },
                    { id: 1, name: 'Maximal Strength / Power' },
                    { id: 2, name: 'Hypertrophy' },
                    { id: 3, name: 'Strength Endurance (Fat Loss)' }
                ],
                trainingAges: [
                    { id: null, name: '-' },
                    { id: 1, name: 'Beginner' },
                    { id: 2, name: 'Novice' },
                    { id: 3, name: 'Intermediate' },
                    { id: 4, name: 'Advanced' },
                    { id: 5, name: 'Elite' }
                ]
            }
        },

        async created() {
            const response = await axios.post(`${process.env.VUE_APP_API}/athlete/getathlete`, 'athleteId=' + this.$route.params.athleteId);
            this.athlete = response.data;
            if (this.$route.params.athleteId != "0") {
                await this.getRecommendations();                
            }
            const mostRecentAthleteObservationResponse = await axios.post(`${process.env.VUE_APP_API}/athlete/getmostrecentathleteobservation`, 'athleteId=' + this.$route.params.athleteId);
            this.mostRecentAthleteObservation = mostRecentAthleteObservationResponse.data;
            useHead({
                title: `Cerebro: Athlete: ${this.athlete == null ? 'New Athlete' : this.athlete.fullName}`
            });
        },

        computed: {
            slidePanelsClosed() {
                return !this.showTips && !this.showRecommendations && !this.showNotes;
            },

            isSidebarPinned() {
                return this.isTipsPinned || this.isRecommendationsPinned || this.isNotesPinned;
            }, 

            showSaveButtonForEditAthleteName() {
                return (this.$route.params.athleteId != "0") && this.isEditAthleteName;
            },

            isExistingAthlete() {
                return (this.$route.params.athleteId != "0");
            },
            fullName() {
                console.log('this athlete: ', this.athlete);
                return this.athlete.fullName;
            }
        },

        methods: {
            toggleMenu(type) {
                switch (type) {
                    case 'tips':
                        this.showTips = !this.showTips;
                        this.showRecommendations = false;
                        this.showNotes = false;
                        this.isTipsPinned = false;
                        this.isRecommendationsPinned = false;
                        this.isNotesPinned = false;
                        break;
                    case 'recommendations':
                        this.showRecommendations = !this.showRecommendations;
                        this.showTips = false;
                        this.showNotes = false;
                        this.isTipsPinned = false;
                        this.isRecommendationsPinned = false;
                        this.isNotesPinned = false;
                        break;
                    default:
                        this.showNotes = !this.showNotes;
                        this.showTips = false;
                        this.showRecommendations = false;
                        this.isTipsPinned = false;
                        this.isRecommendationsPinned = false;
                        this.isNotesPinned = false;
                        break;
                }
            },
            pinSidebar(sidebarType) {
                switch (sidebarType) {
                    case 'tips':
                        this.isTipsPinned = true;
                        this.isRecommendationsPinned = false;
                        this.isNotesPinned = false;
                        break;
                    case 'recommendations':
                        this.isTipsPinned = false;
                        this.isRecommendationsPinned = true;
                        this.isNotesPinned = false;
                        break;
                    case 'notes':
                        this.isTipsPinned = false;
                        this.isRecommendationsPinned = false;
                        this.isNotesPinned = true;
                        break;
                }
            },
            unpinSidebar(sidebarType) {
                this.isTipsPinned = false;
                this.isRecommendationsPinned = false;
                this.isNotesPinned = false;

                switch (sidebarType) {                    
                    case 'tips':
                        this.showTips = true;
                        break;
                    case 'recommendations':
                        this.showRecommendations = true;
                        break;
                    case 'notes':
                        this.showNotes = true;
                        break;
                }
            },
            lockType(type) {
                switch (type) {
                    case 'tips':
                        if (this.isTipsPinned) { return 'lock'; }
                        else { return 'lock_open'; }
                    case 'recommendations':
                        if (this.isRecommendationsPinned) { return 'lock'; }
                        else { return 'lock_open'; }
                    case 'notes':
                        if (this.isNotesPinned) { return 'lock'; }
                        else { return 'lock_open'; }
                }
            },


            showEditAthleteName() {
                if (this.athlete.athleteId == null) {
                    this.isEditAthleteName = true;
                }
                return this.isEditAthleteName;
            },
            flagChange() {
                this.isDataChanged = true;
                
            },
            flagObservationChange() {
                this.isObservationDataChanged = true;
            },
            linkTrainingMethod() {
                alert("Future Functionality: Link Training Method to Athlete");
            },
            editAthleteName() {
                this.isEditAthleteName = true;
            },
            autoSave() {
                if (this.$route.params.athleteId != "0") {
                    setInterval(() => {
                        if (this.isDataChanged) {
                            this.saveAthlete();
                        }
                    }, 10000);
                }
            }, 
            async saveAthleteName() {
                await this.saveAthlete();
                this.isEditAthleteName = false;
            }, 
            saveQuestionnaire(questionnaireType) {
                switch (questionnaireType) {
                    case 'stress':
                        this.saveAthlete();
                        break;
                    case 'sleep':
                        this.saveAthlete();
                        break;
                    case 'trainingAge':
                        this.saveAthlete();
                        break;
                }                
            },
            async saveAthlete() {
                this.isSavingAthlete = true;
                this.athlete.fullName = "";
                this.athlete.clientFor = "";
                this.athlete.trainingAgeText = "";
                const response = await axios.post(`${process.env.VUE_APP_API}/athlete/addupdateathlete`, this.athlete);
                if (this.$route.params.athleteId == "0") {
                    window.location.href = "/athletes";
                }                
                this.athlete.athleteQuestionnaireSleepDto.questionnaireResult = response.data.athleteQuestionnaireSleepDto.questionnaireResult;
                this.athlete.athleteQuestionnaireStressDto.questionnaireResult = response.data.athleteQuestionnaireStressDto.questionnaireResult;
                this.athlete.athleteQuestionnaireTrainingAgeDto = response.data.athleteQuestionnaireTrainingAgeDto;
                this.isDataChanged = false;
                this.isSavingAthlete = false;
                this.athlete.fullName = this.athlete.firstName + ' ' + this.athlete.lastName;
            },
            async saveObservationData() {
                await axios.post(`${process.env.VUE_APP_API}/athlete/addupdateathleteobservation`, this.athleteObservation);
                this.isObservationDataChanged = false;
                this.isObservationEntry = false;
                this.isDataChanged = false;
                const mostRecentAthleteObservationResponse = await axios.post(`${process.env.VUE_APP_API}/athlete/getmostrecentathleteobservation`, 'athleteId=' + this.$route.params.athleteId);
                this.mostRecentAthleteObservation = mostRecentAthleteObservationResponse.data;
            },
            async getRecommendations() {
                var trainingMethodRecommendationInputDto = {
                    bloodPressureSystolic: null,
                    bloodPressureDiastolic: null,
                    fitnessGoal: this.athlete.fitnessGoal,
                    restingHeartRate: null,
                    stressScore: null,
                    trainingAge: this.athlete.trainingAge,
                    workoutsPerWeek: null,
                    workoutSessionTimeInMinutes: null,
                    workoutStructure: null
                };
                const recommendationResponse = await axios.post(`${process.env.VUE_APP_API}/recommendation/getrecommendations`, trainingMethodRecommendationInputDto);
                this.recommendations = recommendationResponse.data.filter(x => x.totalRecommendationWeighted >= 5);
            },
            async recommendationInputChange() {
                this.flagChange();
                await this.getRecommendations();
            },
            async cancelObservationData() {
                this.isObservationEntry = false;
                this.isObservationDataChanged = false;
            },
            async viewAddObservationData() {
                this.isObservationEntry = true;
                this.isObservationDataChanged = false;
            }
        },
        mounted() {
            this.isEditAthleteName = this.$route.params.athleteId == 0;
            this.autoSave();
        }
    });
</script>

<style scoped>
    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: 0.3;
        z-index: 99;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: all 0.4s ease-in-out;
    }

    .fade-enter-from,
    .fade-leave-to {
       opacity: 0;
    }

    .slide-left-enter-active,
    .slide-left-leave-active {
        transition: all 0.4s ease-in-out;
    }

    .slide-left-enter-from,
    .slide-left-leave-to {
        transform: translateX(500px);
    }
</style>
