/*
* @param value - A value to be checked for phone-number-like format
*/

export const phoneNumber = (value: string) => {
    const matchNumber = /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g; // matches phone-number-like input
    const matchInvalidInput = /[^()+-\s\d]+/g; // inverse character set of acceptable input
    const containsValidNumber = matchNumber.test(value);
    const containsInvalidInput = !!value.match(matchInvalidInput)?.length;

    return !containsInvalidInput && containsValidNumber;
}

// get this from endpoint maybe?
/* const enumErrorTypes = [

]; */


interface IinvalidAthleteValidationErrorData {
    athleteId: number;
    fullName: string;
}

interface IValidationError {
    field: string;
    message: string;
    data: IinvalidAthleteValidationErrorData;
}

export const formatErrorMessage = ({field, message, data}: IValidationError) => {
    // single use for duplicateAthlete, but extend this to any type (need eums of errors to do this efficiently and reusably)
    if (field == 'duplicateAthlete') {
        const {
            athleteId,
            fullName,
        } = data;
        return `Athlete ${fullName} already exists. <a href="/athlete/${athleteId}" rel="noopener">View profile</a>?`;
    }

    return message;
}
