<template>
    <div class="container-fluid">
        <div class="row">

            <!-- Notifications Section -->
            <div class="col-4 ps-0">
                <div class="card border-primary mb-3">
                    <div class="card-header"><h5>Notifications</h5></div>
                    <div class="card-body">
                        <NotificationShort icon="fitness_center" message="Jeff Bezoz needs a new program" dueDate="20 Aug 2022" isNew="false" />
                        <NotificationShort icon="fitness_center" message="Elon Musk needs a new program" dueDate="20 Aug 2022" isNew="false" />
                        <NotificationShort icon="fitness_center" message="Joe King needs a new program" dueDate="22 Aug 2022" isNew="true" />
                    </div>
                </div>
            </div>

            <div class="col">
                <!-- Welcome Message -->
                <div class="row">
                    <h2 class="text-secondary text-start">Welcome Zoe</h2>
                </div>

                <!-- Quick Actions -->
                <div class="row">
                    <div class="card border-primary mb-3 p-0">
                        <div class="card-header text-secondary text-start">Quick Actions</div>
                        <div class="card-body">
                            <div class="container p-0">
                                <div class="row">
                                    <div class="col-6">
                                        <form class="d-flex">
                                            <input class="form-control me-sm-2" type="text" placeholder="Search">
                                            <button class="btn btn-secondary my-2 my-sm-0" type="submit">Search</button>
                                        </form>
                                    </div>

                                    <div class="col-3">
                                        <AddAthlete>Add Client</AddAthlete>
                                    </div>

                                    <div class="col-3">
                                        <button type="button" class="btn btn-primary">New Program</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Saved Education -->
                <div class="row">
                    <div class="card border-primary mb-3 p-0">
                        <div class="card-header text-start">Saved Education</div>
                        <div class="card-body">
                            <div class="container p-0">
                                <div class="row">
                                    <div class="col-4">
                                        <div style="padding:56.25% 0 0 0;position:relative;">
                                            <iframe src="https://player.vimeo.com/video/733884877?h=b28fdd595c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Brisbane Training Methods Seminar-"></iframe>
                                        </div>
                                    </div>

                                    <div class="col-4">
                                        <div style="padding:56.25% 0 0 0;position:relative;">
                                            <iframe src="https://player.vimeo.com/video/737453736?h=23d90112d0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Nick Koller&amp;#039;s Testimony.mp4"></iframe>
                                        </div>
                                    </div>

                                    <div class="col-4">
                                        <div style="padding:56.25% 0 0 0;position:relative;">
                                            <iframe src="https://player.vimeo.com/video/721836500?h=f8e90f1994&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="6. Ranges of BP..mp4"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>

    <!-- TODO: determine how to do the quick tips side-bar experience -->
    <!--<div class="container-fluid" style="position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 999;">
        <div class="row m-2">

            <div class="col-4 ps-0"></div>

            <div class="col"></div>-->


            <!-- Quick Tips and Recommended Education (animate on hide option) -->
            <!--<div class="col-2 pe-0">
                <div class="card border-primary mb-3">
                    <div class="card-header">Quick Tips</div>
                    <div class="card-body">
                        <h4 class="card-title">Data to be Added</h4>
                        <p class="card-text">TO DO: hide / show</p>
                    </div>
                    <div class="card-header">Recommendations</div>
                    <div class="card-body">
                        <h4 class="card-title">Data to be Added</h4>
                        <p class="card-text">TO DO: hide / show</p>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
</template>

<script>
    // @ is an alias to /src
    import { defineComponent } from "vue";
    import NotificationShort from '@/components/notification-short.vue';
    import AddAthlete from '@/components/athlete-add.vue';

    export default defineComponent({
        components: { NotificationShort, AddAthlete }
    });
</script>
