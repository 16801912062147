<template>
    <div class="pinned-button-group" :style="{ 'right': rightValue, 'left': leftValue }">
        <slot></slot>
    </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({

    props: {
        leftValue: {
            type: String,
            default: ''
        },

        rightValue: {
            type: String,
            default: ''
        }
    },

});

</script>

<style scoped>
    .pinned-button-group {
        position: absolute;
        top: 110px;
        z-index: 9;
    }
</style>