<template>
    <div class="card-body text-start">
        <dl>
            <dt>General Search</dt>
            <dd>Search will look for first and last names</dd>

            <dt>Inclusive Search</dt>
            <dd>Including an plus '+' character at the start of a term will force search to ensure that term is included</dd>

            <dt>Exclusive Search</dt>
            <dd>Including a minus '-' character at the start of a term will force search to ensure that term is not included</dd>

            <dt>Quoted Search</dt>
            <dd>Surrounding terms with quotation '"' marks will ensure the whole phrase is included; particularly helpful for spaces in a name</dd>
        </dl>
    </div>
</template>

<script>

</script>