<template>
    <template v-if="recommendationWeight == 5">
        <img src="@/assets/green-tick-small.png" />
    </template>
    <template v-else-if="recommendationWeight > 0">
        <img src="@/assets/yellow-partial-small.png" />
    </template>
</template>

<script>
    export default {
        name: 'RecommendationInputStatus',
        props: {
            recommendationWeight: {
                type: Number,
                default: 0
            }
        }
    }
</script>