<template>
    <div :class="['pinned-button btn-primary', { 'isActive': isActive }]">
        <slot></slot>
    </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({

    props: {
        isActive: {
            type: Boolean,
            default: false
        }
    }

});
</script>

<style scoped>
    .pinned-button {
        display: block;
        width: fit-content;
        cursor: pointer;
        margin-bottom: 5px;
        padding: 8px 8px 3px 8px;
        border-radius: 3px;
    }

    .isActive {
        background-color: #288bc4;
        border-color: #2683b9;
        background-image: linear-gradient(#FE7F2D, #FE7F2D 60%, #FE7F2D);
    }
</style>